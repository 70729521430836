<div class="d-flex flex-column bg-theme text-themeLight min-vh-100">
    <div class="head-padding bg-white text-center">
        <img src="assets/images/site/login-logo.png" alt="Logo">
    </div>
    <div class="login-content flex-grow-1 text-center">
        <div class="container">
            <div class="py-83 mw-440 mx-auto">
                <h1 class="mb-1">Skills Portfolio</h1>
                <p class="mb-40">Equipping Yourself for the Future.</p>
                <form [formGroup]="loginForm">
                    <div class="form-floating floating-custom mb-20">
                        <input formControlName="loginEmail" type="email" class="form-control form-custom"
                            id="floatingInput" placeholder="email" [email]="true"
                            [ngClass]="{ 'is-invalid': submitted && hasError('loginEmail') }">
                        <label class="label-custom" for="floatingInput">Email</label>
                        <div *ngIf="submitted && hasError('loginEmail')" class="invalid-feedback">
                            <div *ngIf="hasError('loginEmail','required')">Email is required</div>
                            <div *ngIf="hasError('loginEmail','email')">Email is not valid</div>
                        </div>
                    </div>
                    <div class="form-floating floating-custom mb-20">
                        <input formControlName="loginPassword" type="password" class="form-control form-custom"
                            id="floatingPassword" placeholder="password"
                            [ngClass]="{ 'is-invalid': submitted && hasError('loginPassword') }">
                        <label class="label-custom" for="floatingPassword">Password</label>
                        <div *ngIf="submitted && hasError('loginPassword')" class="invalid-feedback">
                            <div *ngIf="hasError('loginPassword','required')">Password is required</div>
                        </div>
                    </div>
                    <div class="mb-20" *ngIf="challengeInput">
                        <div class="form-floating floating-custom">
                            <input formControlName="loginChallenge" type="text" class="form-control form-custom"
                                id="floatingCode" placeholder="code"
                                [ngClass]="{ 'is-invalid': submitted && hasError('loginChallenge') }">
                            <label class="label-custom" for="floatingCode">Challenge code</label>
                            <div *ngIf="submitted && hasError('loginChallenge')" class="invalid-feedback">
                                <div *ngIf="hasError('loginChallenge','required')">Challenge Code is required
                                </div>
                            </div>
                        </div>
                        <p class="login-text">A secure code was sent to your inbox</p>
                    </div>



                    <div class="d-flex align-items-center justify-content-between">
                        <a href="javascript:void(0)" class="a-link" (click)="forgotPassword()">Forgot password?</a>
                        <button type="button" class="btn btn-white" (click)="login()">Login</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>