import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: '[sort-icon]',
  templateUrl: 'sort-icon.component.html',
  styleUrls: ['sort-icon.component.scss']
})
export class SortIconComponent {
  @Input()
  public sortFieldName: string | null = null;

  @Input()
  public currentSortField: unknown = null;

  @Input()
  public currentSortDirection: 'ASC' | 'DESC' | null = null;

  @Input()
  public defaultSortDirection: 'ASC' | 'DESC' = 'ASC';

  @Output()
  updateSorting: EventEmitter<{
    field: string | null
    direction: 'ASC' | 'DESC'
  }> = new EventEmitter();

  public isCurrentSortField() {
    return String(this.sortFieldName).toLowerCase() === String(this.currentSortField).toLowerCase();
  }

  public getNextSortDirection() {
    if (!this.isCurrentSortField() || !this.currentSortDirection) {
      return this.defaultSortDirection;
    }

    return this.currentSortDirection === 'ASC' ? 'DESC' : 'ASC';
  }

  public toggleSorting() {
    this.updateSorting.emit({
      field: this.sortFieldName,
      direction: this.getNextSortDirection()
    });
  }
}
