<div class="d-flex flex-column bg-theme text-themeLight min-vh-100">
  <div class="head-padding bg-white text-center">
    <img src="assets/images/site/login-logo.png" alt="Logo">
  </div>
  <div class="login-content flex-grow-1 text-center">
    <div class="container">
      <div class="py-83 mw-440 mx-auto">
        <h1 class="mb-1">Skills Portfolio</h1>
        <p class="mb-40">Equipping Yourself for the Future.</p>
        <form [formGroup]="resetForm" autocomplete="off">
          <div class="form-floating floating-custom mb-20">
            <input formControlName="password" type="password"
              class="form-control form-custom {{(resetForm.controls['password'].dirty && ( resetForm.hasError('required', 'password') || resetForm.hasError('password', 'password') ) )?'is-invalid':'is-valid'}}"
              id="floatingInput" placeholder="Password" required>
            <label class="label-custom" for="floatingInput">Password</label>
            <div class="invalid-feedback" *ngIf="resetForm.controls['password'].dirty">
              <small *ngIf="resetForm.hasError('required', 'password')"
                class="form-text text-danger validation-text">Password is required
              </small>
            </div>
          </div>
          <div class="form-floating floating-custom mb-20">
            <input formControlName="c_password" type="password"
              class="form-control form-custom {{(resetForm.controls['c_password'].dirty && ( resetForm.hasError('required', 'c_password') || resetForm.hasError('c_password', 'c_password') ) )?'is-invalid':'is-valid'}}"
              id="floatingPassword" placeholder="Repeat password" [ngClass]="{ 'is-invalid': submitted  }" required>
            <label class="label-custom" for="floatingPassword">Repeat password</label>
            <div class="invalid-feedback" *ngIf="resetForm.controls['c_password'].dirty">
              <small *ngIf="resetForm.hasError('required', 'c_password')"
                class="form-text text-danger validation-text">Confirm password is
                required
              </small>
              <small *ngIf="resetForm.hasError('confirmedValidator', 'c_password')"
                class="form-text text-danger validation-text">Password and Confirm Password must be
                match.</small>
            </div>
          </div>
          <div class="form-floating floating-custom mb-20">
            <small *ngIf="loginIncorrect" class="form-text text-danger validation-text">{{loginIncorrect_msg}}</small>
          </div>
          <div class="d-flex align-items-center justify-content-end">
            <button type="button" class="btn btn-white" id="loginBtn" (click)="resetPassword()">
              Set Password
            </button>
            <button type="button" class="btn btn-danger" id="loginBtn" [routerLink]="['/user/login']">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>