<div class="overlay" [ngClass]="{ 'active': isActive}" (click)="handleOverlayClick()"></div>
<div id="sidebarMenu" [ngClass]="{ 'active': isActive}">
  <div class="scrollbar">
    <div class="sidebarForm">
      <form [formGroup]="skillForm">
        <div class="mb-30">
          <label class="label-custom" for="sub_category">SKILL</label>
          <div class="styled-ngselect">
            <ng-select [items]="allSubcategories" bindLabel="text" bindValue="value" formControlName="sub_category"
              class="form-control form-control myselect-custome">
            </ng-select>
          </div>

          <div>
            Can't see your skill or achievement here? <a
              href="mailto:n.welsh@genesis.qld.edu.au?subject=New%20Skill%20Request" target="_blank">Contact Us</a>
          </div>
        </div>
        <div class="mb-30">
          <label class="label-custom" for="category">CATEGORY</label>
          <div class="">
            <ng-select [items]="mainCategories" bindLabel="text" bindValue="value" formControlName="category"
              *ngIf="mainCategories.length > 1" class="form-control form-control myselect-custome">
            </ng-select>
            <input class="form-control" type="text" readonly *ngIf="mainCategories.length <= 1"
              [value]="selectedCategoryText">
          </div>
        </div>
        <div class="mb-30">
          <label class="label-custom mb-1" for="description">Description</label>
          <label class="label-small mb-2 w-100">Insert description..</label>
          <input formControlName="description" type="text" id="description" class="form-control form-custom" />
        </div>
        <div class="mb-30">
          <label class="label-custom" for="year_completed">Year Completed</label>
          <div class="styled-select">
            <select formControlName="year_completed" class="form-control form-custom" id="year_completed">
              <option *ngFor="let year of getYears()" [value]="year">{{ year }}</option>
            </select>
          </div>
        </div>
        <div class="mb-30">
          <label class="label-custom mb-1" for="achievement">Means of Achievement</label>
          <div class="styled-select">
            <select formControlName="achievement" class="form-control form-custom" id="achievement">
              <option value="certificate">Certificate</option>
              <option value="photograph">Photograph</option>
              <option value="pdf_document">PDF document</option>
              <option value="other">Other</option>
            </select>
          </div>
        </div>

        <div class="mb-30" *ngIf="f['achievement'].value === 'other'">
          <label class="label-custom mb-1" for="means_description">Description</label>
          <label class="label-small mb-2 w-100">Insert description..</label>
          <input formControlName="means_description" type="text" id="description" class="form-control form-custom" />
        </div>

        <div class="mb-20">
          <label class="label-custom mb-20" for="attachment">Attachments</label>
          <div class="file-content mb-20" *ngFor="let skillFile of skillFiles; let i = index">
            <div>
              <div>
                <img src="assets/images/site/file-pdf.png" alt="file">
                <h4>{{skillFile.name}}</h4>
              </div>
              <div>
                <button *ngIf="skillFile.id" class="btn btnClose" (click)="removeUserFile(skillFile.id)" title="Remove">
                  <i class="fa-solid fa-close"></i>
                </button>
                <button *ngIf="skillFile.id" class="btn btnDownload" (click)="downloadUserFile(skillFile.id)"
                  title="Download">
                  <i class="fa-solid fa-arrow-down"></i>
                </button>
                <button *ngIf="!skillFile.id" class="btn btnClose" (click)="cancelCurrentSkill(i)" title="Cancel">
                  <i class="fa-solid fa-close"></i>
                </button>
              </div>
            </div>
          </div>
          <label class="filelabel">
            <i class="fa-solid fa-plus"></i>
            <input type="file" (change)="addSkillFile($event)" #fileUpload class="file-upload" multiple>
          </label>
        </div>

        <div class="row">
          <div class="col-6 mt-2">
            <a class="btn btn-themePrimary d-block" (click)="saveDraft()">Save Draft</a>
          </div>
          <div class="col-6 mt-2">
            <a class="btn btn-theme1 d-block" (click)="addSkill()" *ngIf="!isEdit">Save</a>
            <a class="btn btn-theme1 d-block" (click)="editSkill()" *ngIf="isEdit">Save</a>
          </div>
          <div class="col-6 mt-2" *ngIf="isDraft">
            <a class="btn btn-themelight1 d-block" (click)="deleteDraft()">Delete Draft</a>
          </div>
          <div class="col-6 mt-2">
            <a class="btn btn-theme d-block" (click)="cancel()">Cancel</a>
          </div>
          <div class="col-6 mt-2" *ngIf="isEdit">
            <a class="btn btn-danger d-block" (click)="deleteSkill()">Delete Skill</a>
          </div>
        </div>
      </form>
    </div>
  </div>

</div>
