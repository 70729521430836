import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { createUrl, getPublicRoutesHeaders } from '../util/api.util';
import { switchMap } from 'rxjs/operators';
import { IJWTPayload } from '../model/auth.model';
import { SecureService } from './secure.service';
import { forgotRequestData, loginRequestData, registerRequestData, resetRequestData } from '../model/response.model';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private __http: HttpClient,
        private __secureService: SecureService
    ) { }

    /**
  * login
  */
    public login(credentials: Record<string, loginRequestData>) {
        return this.__http.post<IJWTPayload>(createUrl('auth', 'login'), credentials, {
            headers: getPublicRoutesHeaders()
        })
            .pipe(
                switchMap((payload) => {
                    return this.__secureService.persistJwtPayload(payload)
                })
            );
    }

    public register(credentials: Record<string, registerRequestData>) {
        return this.__http.post(createUrl('auth', 'register'), credentials, {
            headers: getPublicRoutesHeaders()
        });
    }

    public refreshToken(credentials: Record<string, {
        accessToken: string,
        refreshToken: string
    }>) {
        return this.__http.post(createUrl('auth', 'refresh'), credentials, {
            headers: getPublicRoutesHeaders()
        });
    }

    public reset(credentials: Record<string, resetRequestData>) {
        return this.__http.post<IJWTPayload>(createUrl('auth', 'reset'), credentials, {
            headers: getPublicRoutesHeaders()
        })
            .pipe(
                switchMap((payload) => {
                    return this.__secureService.persistJwtPayload(payload)
                })
            );
    }

    public forgot(credentials: Record<string, forgotRequestData>) {
        return this.__http.post(createUrl('user_auth', 'forgot'), credentials, {
            headers: getPublicRoutesHeaders()
        });
    }
}
