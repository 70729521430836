import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../service/auth.service';
import { Router } from '@angular/router';
import { validateForm } from 'src/app/util/form.util';
import { logger } from 'src/app/util/logger.util';

@Component({
    selector: 'app-forgot',
    templateUrl: './forgot.component.html',
    styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {

    title = 'Forgot Form';
    public forgotForm: FormGroup;
    public submitted: boolean = false;
    public loginFormCheck: boolean = true;
    public forgotIncorrect: boolean = false;
    public forgotIncorrect_msg: string = "";

    constructor(
        private __authService: AuthService,
        private __formBuilder: FormBuilder,
        private __router: Router,
    ) {
        this.forgotForm = this.__formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });
    }
    ngOnInit() {
    }

    public reset(): void {
        this.submitted = true;
        logger.setLogUser(this.forgotForm.controls["email"].value);

        validateForm({
            form: this.forgotForm,
        });
        this.__authService.forgot(this.forgotForm.value).subscribe((response) => {
            if (response) {
                alert(`An email was sent to ${this.forgotForm.controls["email"].value}`);
                this.__router.navigate(['/user/login']);
            } else {
                this.forgotIncorrect = true;
                this.forgotIncorrect_msg = 'Invalid Username Or Password !';
            }
        })
    }

}
