import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { createUrl } from '../util/api.util';
import { IUserSkill } from '../model/user-skill.model';
import { StatusMessageData } from '../model/api.model';

@Injectable({
  providedIn: 'root'
})
export class AdminUserService {

  constructor(
    private __http: HttpClient,
  ) { }

  /**
   * userSkillAdd
   */
  // public userSkillAdd(credentials: {}) {
  //   return this.__http.post<StatusMessageData<{skill:IUserSkill}>>(createUrl('user_skill', 'add'), credentials);
  // }
  /**
   * userSkillEdit
   */
  // public userSkillEdit(credentials: {}) {
  //   return this.__http.post<StatusMessageData<{skill:IUserSkill}>>(createUrl('user_skill', 'edit'), credentials);
  // }
  /**
   * userSkillHexaSequenceEdit
   */
  // public userSkillHexaSequenceEdit(credentials: {}) {
  //   return this.__http.post<StatusMessageData<{skill:IUserSkill}>>(createUrl('user_skill', 'edit_hexa_sequence'), credentials);
  // }
  /**
   * userSkillListing
   */
  public adminUserListing(credentials: {}) {
    return this.__http.post(createUrl('admin-user', 'list'), credentials);
  }

  public adminUserAdd(credentials: {}) {
    return this.__http.post(createUrl('admin', 'register'), credentials);
  }

  public adminUserEdit(data: {}) {
    return this.__http.post(createUrl('admin-user', 'edit_user'), data);
  }
  public getUserDetails(id: number) {
    return this.__http.get(createUrl('admin-user', 'get_user',id));
  }
  public adminUserUpdate(credentials: {}) {
    return this.__http.post(createUrl('admin-user', 'update'), credentials);
  }
}
