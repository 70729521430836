import { Injectable } from '@angular/core';
import { async } from '@angular/core/testing';
import { CanActivate, Router } from '@angular/router';
import { map } from 'rxjs';
import { UserAuthService } from '../service/user-auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private __userAuthService: UserAuthService
  ) { }

  async canActivate() {
    return await this.__userAuthService.getCurrentUserAdmin().toPromise();
  }

  
}
