<div class="main-content print-content">
  <div class="container">
    <div class="border-box">
      <div class="print-body" #printContent [ngClass]="{ 'printMode': printMode }">
        <div class="print-head">
          <h5 class="text-theme1">Skills Portfolio</h5>
          <div class="print-title">
            <div>
              <h1 class="text-theme small">{{loginUser && loginUser.firstname}} {{loginUser && loginUser.lastname}}</h1>
              <div class="mb-3 text-uppercase">
                <p class="text-theme1">SCHOOL</p>
                <h3 class="text-theme mb-0">{{loginUser && loginUser.school || "GENESIS CHRISTIAN COLLEGE"}}</h3>
              </div>
              <div class="mb-3">
                <p class="text-theme1">Graduating Year</p>
                <h3 class="text-theme mb-0">{{loginUser && loginUser.graduting_class}}</h3>
              </div>
              <div class="mb-3" *ngIf="loginUser && loginUser.linkedin_url">
                <p class="text-theme1">Linkedin URL</p>
                <h3 class="text-theme mb-0">{{loginUser && loginUser.linkedin_url}}</h3>
              </div>
            </div>
            <div class="text-end">
              <div class="mb-3">
                <p class="text-theme1">DATE OF BIRTH</p>
                <h3 class="text-theme mb-0">{{loginUser && loginUser.dob_string}}</h3>
              </div>
              <div class="mb-3">
                <p class="text-theme1">Email</p>
                <h3 class="text-theme mb-0">{{loginUser && loginUser.email}}</h3>
              </div>
              <div class="mb-3" *ngIf="loginUser && loginUser.phone_number">
                <p class="text-theme1">Mobile</p>
                <h3 class="text-theme mb-0">{{loginUser && loginUser.phone_number}}</h3>
              </div>
            </div>
          </div>

        </div>
        <hr class="mt-19">
        <div class="print-main" *ngIf="Work.length">
          <div class="print-block theme-skyblue">
            <div class="img-block">
              <img src="assets/images/site/work-ready.png" alt="Work Ready">
            </div>
            <h3>Work Ready</h3>
          </div>
          <div class="table-responsive">
            <table class="table table-mob-custom table-print">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Year completed</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let print of Work">
                  <td table-head="Title:">{{getDisplayText(print)}}</td>
                  <td table-head="Year completed:">{{print.year_completed}}</td>
                  <td table-head="Description:">{{print.description}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <hr *ngIf="future.length && Work.length">
        <div class="print-main" *ngIf="future.length">
          <div class="print-block theme-brown">
            <div class="img-block">
              <img src="assets/images/site/century.png" alt="Future Ready Skills">
            </div>
            <h3>Future Ready Skills</h3>
          </div>
          <div class="table-responsive">
            <table class="table table-mob-custom table-print">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Year completed</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let print of future">
                  <td table-head="Title:">{{getDisplayText(print)}}</td>
                  <td table-head="Year completed:">{{print.year_completed}}</td>
                  <td table-head="Description:">{{print.description}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <hr *ngIf="(future.length || Work.length) && excellence.length">
        <div class="print-main" *ngIf="excellence.length">
          <div class="print-block  theme-blue">
            <div class="img-block">
              <img src="assets/images/site/excellence.png" alt="Excellence">
            </div>
            <h3>Excellence</h3>
          </div>
          <div class="table-responsive">
            <table class="table table-mob-custom table-print">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Year completed</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let print of excellence">
                  <td table-head="Title:">{{getDisplayText(print)}}</td>
                  <td table-head="Year completed:">{{print.year_completed}}</td>
                  <td table-head="Description:">{{print.description}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <hr *ngIf="(future.length || Work.length || excellence.length ) && extra_mile.length">
        <div class="print-main" *ngIf="extra_mile.length">
          <div class="print-block theme-orange">
            <div class="img-block">
              <img src="assets/images/site/extra-mile.png" alt="The Extra Mile">
            </div>
            <h3>The Extra Mile</h3>
          </div>
          <div class="table-responsive">
            <table class="table table-mob-custom table-print">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Year completed</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let print of extra_mile">
                  <td table-head="Title:">{{getDisplayText(print)}}</td>
                  <td table-head="Year completed:">{{print.year_completed}}</td>
                  <td table-head="Description:">{{print.description}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="mx-4 my-4 text-end">
        <button class="btn btn-theme1 btn-iconText" (click)="openModal(template)">
          <i class="fa-solid fa-print"></i>Print Skill PDF
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #template>
  <div class="modal-header" #content>
    <h5 class="modal-title pull-left">Select skills for print</h5>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="printForm">
      <div class="table-responsive mb-4">
        <table class="table table-mob-custom table-skill">
          <thead>
            <tr>
              <th>Select</th>
              <th>Title</th>
              <th>Year completed</th>
              <!-- <th>Description</th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let skill of skillData">
              <td table-head="Select:">
                <div class="form-check form-check-inline ps-0 m-0">
                  <input [value]="skill.id" (change)="onCheckboxChange($event)" [checked]="isChecked(skill.id)"
                    class="form-check-input ms-0" type="checkbox" id="{{skill.id}}">
                </div>
              </td>
              <td table-head="Title:">{{getDisplayText(skill)}}</td>
              <td table-head="Year completed:">{{skill.year_completed}}</td>
              <!-- <td table-head="Description:">{{print.description}}</td> -->
            </tr>
          </tbody>
        </table>
        <p class="error text-danger mt-2" *ngIf="this.printForm.controls['skills'].errors?.['required']">
          Checkbox is required, select atleast one value.
        </p>
      </div>
      <div class="form-group form-check mb-3">
        <input formControlName="downloadSkill" type="checkbox" class="form-check-input" id="downloadSkill">
        <label class="form-check-label" for="downloadSkill">Download skill atttachments
        </label>
      </div>
    </form>
    <div class="text-end">
      <button type="button" class="btn btn-theme" (click)="printSelcetedSkill()">Print</button>
      <button type="button" class="btn btn-danger" (click)="modalRef?.hide()">Close</button>
    </div>
  </div>
</ng-template>