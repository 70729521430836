import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { WellFormedError } from 'src/app/model/error.model';
import { SkillService } from 'src/app/service/skill.service';
import { validateForm } from 'src/app/util/form.util';
import { logger } from 'src/app/util/logger.util';
import { IUserGridItem } from '../skill-grid/skill-grid-model';
import { LocalStorageService } from 'ngx-store';
import { environment } from "src/environments/environment";
import { UserFileService } from 'src/app/service/user-file.service';
import { hasKey } from 'src/app/util/object.util';
import { categoryData } from 'src/app/model/skill-category.model';
import { UserAuthService } from 'src/app/service/user-auth.service';

const acceptedMimeTypes = [
  { mime: "application/pdf", extension: "pdf" },
  { mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.document", extension: "docx" },
  { mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", extension: "xlsx" },
  { mime: "application/vnd.openxmlformats-officedocument.presentationml.presentation", extension: "pptx" },
  { mime: "image/jpeg", extension: "jpg" },
  { mime: "image/jpeg", extension: "jpeg" },
  { mime: "image/png", extension: "png" }
];

const className = "SkillFormComponent";

@Component({
  selector: 'app-skill-form',
  templateUrl: './skill-form.component.html',
  styleUrls: ['./skill-form.component.scss']
})
export class SkillFormComponent {

  // The form the user will use to edit a skill
  public skillForm: FormGroup;

  public newFile: File | null | any = null;
  public uploadPct: number = 0;
  public skillFiles: any = [];

  public mainCategories: typeof categoryData = [];

  // All unique subcategories
  public allSubcategories = this.sortSubCategory(
    categoryData
      .map(category => category.children)
      .reduce((prev, val) => {
        prev.push(...val);
        return prev;
      }, [])
      .reduce((prev, val, idx, self) => {
        if (self.findIndex(item => item.value === val.value) === idx) {
          prev.push(val);
        }

        return prev;
      }, [] as typeof categoryData[number]['children'])
  );

  private categories: typeof categoryData = categoryData;
  private userId: number | null = null;

  get isEdit(): boolean {
    return !!(this.activeGridItem && this.activeGridItem.skill && this.activeGridItem.skill.id);
  }

  /**
   * @description Fetches the display text of the currently selected category (if applicable)
   */
  get selectedCategoryText(): string {
    const categoryValue = this.f['category'].value;

    if (!categoryValue) {
      return 'No Category';
    }

    const category = this.categories.find(category => category.value === categoryValue);

    if (!category) {
      return 'No Category';
    }

    return category.text;
  }
  // Determines if this is a 'true' draft where either the id is null, or there is no original skill
  get isDraft(): boolean {
    return !!(
      this.activeGridItem &&
      this.activeGridItem.skill &&
      this.activeGridItem.skill.isDraft &&
      (!this.activeGridItem.skill.id || this.activeGridItem.originalSkill)
    );
  }

  // Convenience method for accessing form data
  get f(): Record<string, AbstractControl> {
    return this.skillForm.controls;
  }

  @ViewChild('fileUpload')
  private fileUploadElement!: ElementRef<HTMLInputElement>;

  // Close the form if esc is pressed
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape' && this.isActive) {
      this.handleOverlayClick();
    }
  }

  // Determines if the skill form should be on screen
  @Input()
  isActive: boolean = false;

  // Notifies listeners that the active state was changed by the component
  @Output()
  isActiveChange: EventEmitter<boolean> = new EventEmitter();

  @Output()
  reloadGrid = new EventEmitter();

  // Handles the grid item that was selected before opening the form. Fills or clears the form depending on whether or not a skill is present in the selected grid
  @Input()
  public activeGridItem: IUserGridItem | null = null;

  constructor(
    private __formBuilder: FormBuilder,
    private __skillService: SkillService,
    private __localStorage: LocalStorageService,
    private __userFileService: UserFileService,
    private __userAuthService: UserAuthService,
  ) {
    this.skillForm = this.__formBuilder.group({
      category: ['', [Validators.required]],
      sub_category: ['', [Validators.required]],
      description: ['', []],
      year_completed: ['', [Validators.required]],
      achievement: ['', [Validators.required]],
      hexa_sequence_number: ['', [Validators.required]],
      id: ['', []],
      means_description: ['', []],
    });
  }

  ngOnInit(): void {
    this.setCurrentUserId();
    this.monitorSkillSelection();
  }

  /**
   * @description Ensures the current userId is populated from the auth state to ensure drafts belong to the right user
   * @returns {void}
   */
  private setCurrentUserId() {
    this.__userAuthService.currentUser.subscribe(user => {
      this.userId = user && user.id
    });
  }

  /**
   * @description Refreshes the skill form from the active grid item. This is deferred to allow any rendering to complete
   */
  public refreshForm() {
    setTimeout(() => {
      this.skillFiles = [];
      if (this.activeGridItem && this.activeGridItem.skill) {
        this.skillForm.patchValue(this.activeGridItem.skill);
        if (this.activeGridItem.skill.userFiles) {
          this.skillFiles = this.activeGridItem.skill.userFiles;
        }
      } else {
        this.skillForm.reset();
      }
    }, 0);
  }

  /**
   * @description Handles the intended behaviour when the overlay is clicked
   */
  public handleOverlayClick() {
    const signature = className + ".handleOverlayClick: ";
    logger.silly(signature + `Click`);
    this.isActiveChange.emit(false);
  }

  /**
   * @description Cancels editing of the current grid item and closes the form
   */
  public cancel() {
    this.handleOverlayClick();
  }

  /**
   * @description Adds a new skill
   */
  public addSkill() {
    if (!this.activeGridItem || !this.activeGridItem.id) {
      throw new Error("Unable to identify target grid for new skill");
    }

    this.skillForm.controls['hexa_sequence_number'].setValue(Number(this.activeGridItem.id));

    validateForm({
      form: this.skillForm,
      onError: () => {
        // this.submitted = true;
      }
    });

    this.__skillService.userSkillAdd(this.skillForm.value).subscribe({
      next: res => {
        this.deleteDraft();

        if (this.activeGridItem && res.data) {
          this.activeGridItem.skill = res.data.skill;
        }

        if (this.skillFiles && this.skillFiles.length) {
          for (let itm of this.skillFiles) {
            this.newFile = itm;
            this.performSkillFileUpload();
          }
        }

        // TODO: Emit that there was a change in skill data
        this.handleOverlayClick();
      },
      error: (err) => {
        const message = "Unknown Error";

        if (err instanceof WellFormedError) {
          alert(err.getMessage(message));
          throw err;
        }

        alert(message);
        console.error(JSON.stringify(err));
        throw new Error(message);
      }
    });
  }

  /**
   * @description Saves a draft copy of the current form and relevant skill id to localstorage for later retrieval
   */
  public saveDraft() {
    const signature = className + ".saveDraft: ";
    if (!this.activeGridItem || !this.activeGridItem.id) {
      throw new Error("Unable to identify target grid for draft skill");
    }

    this.skillForm.controls['hexa_sequence_number'].setValue(Number(this.activeGridItem.id));

    const draftFormData = this.skillForm.value;
    const draftData = JSON.stringify(this.skillForm.value);
    logger.silly(signature + `Saving Draft Data[${draftData}]`);
    this.__localStorage.set(this.getDraftStorageKey(), draftData);

    if (this.activeGridItem.skill && !this.activeGridItem.skill.isDraft) {
      this.activeGridItem.originalSkill = this.activeGridItem.skill;
    }

    this.activeGridItem.skill = {
      isDraft: true,
      ...draftFormData
    };

    this.handleOverlayClick();
  }

  public deleteDraft() {
    const signature = className + ".deleteDraft: ";

    if (!this.activeGridItem || !this.activeGridItem.id) {
      throw new Error("Unable to identify target grid for draft skill");
    }

    logger.silly(signature + `Deleting Draft`);
    this.__localStorage.remove(this.getDraftStorageKey());

    if (this.activeGridItem.skill) {
      delete this.activeGridItem.skill;
    }

    if (this.activeGridItem && this.activeGridItem.originalSkill) {
      this.activeGridItem.skill = this.activeGridItem.originalSkill;
    }

    this.handleOverlayClick();
  }

  private getDraftStorageKey(): string {
    return `skill_hex_${this.activeGridItem && this.activeGridItem.id || 0}_${this.userId}`;
  }

  public editSkill() {
    if (!this.activeGridItem || !this.activeGridItem.id) {
      throw new Error("Unable to identify target grid for new skill");
    }

    if (!this.activeGridItem.skill || !this.activeGridItem.skill.id) {
      throw new Error("Unable to identify target skill for edit");
    }

    this.skillForm.controls['hexa_sequence_number'].setValue(Number(this.activeGridItem.id));
    this.skillForm.controls['id'].setValue(Number(this.activeGridItem.skill.id));

    validateForm({
      form: this.skillForm,
      onError: () => {
      }
    });

    this.__skillService.userSkillEdit(this.skillForm.value).subscribe(
      res => {
        this.deleteDraft();

        if (this.activeGridItem && res.data) {
          this.activeGridItem.skill = res.data.skill;
        }

        if (this.skillFiles && this.skillFiles.length) {
          for (let itm of this.skillFiles) {
            if (!itm.id) { //only for new files uploading
              this.newFile = itm;
              this.performSkillFileUpload();
            }
          }
        }

        this.handleOverlayClick();
      },
      (err) => {
        const message = "Unknown Error";

        if (err instanceof WellFormedError) {
          alert(err.getMessage(message));
          throw err;
        }

        alert(message);
        console.error(JSON.stringify(err));
        throw new Error(message);
      });
  }

  //#region Skill File
  public cancelSkillUpload() {
    this.fileUploadElement!.nativeElement.value = '';
    this.newFile = null;
    this.uploadPct = 0;
    this.reloadGrid.emit();
  }

  public selectFile(targetFile: File) {
    const signature = className + ".selectFile: ";
    // this.newFile = null;

    const acceptedType = acceptedMimeTypes.find(item => item.mime === targetFile.type);

    if (!acceptedType) {
      alert("File does not have an acceptable format. Please select a file from one the following types " + acceptedMimeTypes.map(item => item.extension).join(","));
      throw new Error("Invalid file type " + targetFile.type);
    }

    const fileSize = targetFile.size;
    if (fileSize > environment.uploadMaxSize) {
      alert(`File exceeds max file size of ${environment.uploadMaxSize / 1000000}mb. Please select file with a smaller size.`);
      throw new Error("Invalid file size " + fileSize);
    }

    // this.newFile = targetFile;
    this.skillFiles.push(targetFile);
  }

  public performSkillFileUpload() {
    const signature = className + ".performSkillFileUpload: ";
    logger.silly(signature, this.newFile);

    if (!this.newFile) {
      alert("Select a file before attempting to upload");
      throw new Error("Attempted to upload null file");
    }

    if (!this.activeGridItem || !this.activeGridItem.skill) {
      throw new Error("Attempted to upload file to non-existent skill");
    }

    const userSkill = this.activeGridItem.skill;
    const extension = this.newFile.name.split('.').pop();

    if (!extension) {
      alert("Select a valid file before attempting to upload");
      this.cancelSkillUpload();
      throw new Error("Attempted to upload a file with an invalid extension");
    }

    const fileType = this.newFile.type;

    this.uploadPct = 0;
    this.__userFileService.requestSignedUrl(fileType, extension, this.chunkCount, this.newFile.name, userSkill.id).subscribe({
      next: async result => {
        if (result.status && result.data && result.data.parts && result.data.id) {
          logger.silly(signature, { url: result.data.parts, datas: result });

          this.uploadPct = 10;

          const maxChunkParallelism = 1;
          let chunkPromises: Promise<any>[] = [];
          const chunks: { ETag: string, PartNumber: number }[] = [];
          const chunkValue = Math.round(90 / this.chunkCount);

          for (let i = 0; i < this.chunkCount; i++) {
            if (!this.newFile) {
              logger.info(signature + `Terminating cancelled file upload`);
              return;
            }

            const chunkPromise = new Promise((resolve, reject) => {
              const start = i * environment.uploadChunkSize;
              const end = (i + 1) * environment.uploadChunkSize;
              const blob = i < this.chunkCount
                ? this.newFile!.slice(start, end)
                : this.newFile!.slice(start);

              logger.silly(signature, blob);

              const xhr = new XMLHttpRequest();

              xhr.open('PUT', result.data!.parts[i]);
              xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                  if (xhr.status === 200) {
                    this.uploadPct += chunkValue;

                    resolve({
                      ETag: xhr.getResponseHeader('ETag'),
                      PartNumber: i + 1
                    });
                  }
                  else {
                    reject("Failed to upload file part " + i);
                  }
                }
              };

              xhr.send(blob);
            });

            chunkPromises.push(chunkPromise);

            if (chunkPromises.length >= maxChunkParallelism) {
              const resolvedChunks = await Promise.all(chunkPromises);
              chunks.push(...resolvedChunks);
              chunkPromises = [];
            }
          }

          if (chunkPromises.length >= 1) {
            const resolvedChunks = await Promise.all(chunkPromises);
            chunks.push(...resolvedChunks);
            chunkPromises = [];
          }

          if (!this.newFile) {
            logger.info(signature + `Terminating cancelled file upload`);
            return;
          }

          this.__userFileService.notifyUploadComplete(result.data.id, chunks).subscribe({
            next: () => {
              this.uploadPct = 100;
              alert("File Attachment Upload Complete");
              this.reloadGrid.emit();
              userSkill.userFiles = [];
              userSkill.userFiles.push({
                name: this.newFile.name
              });
              // this.skillFiles = userSkill.userFiles;
              // this.cancelSkillUpload();
            },
            error: err => {
              alert("There was an error marking your upload complete. Please Try Again.");
              this.cancelSkillUpload();
              throw new Error(`Error notifying upload complete`);
            }
          });
        } else {
          alert("There was an error preparing your file for upload. Try another file.");
          this.cancelSkillUpload();
          throw new Error(`Error attempting to prepare signed url with Type[${fileType}] and Extension[${extension}]`);
        }
      },
      error: err => {
        alert("There was an error preparing your file for upload. Try another file.");
        this.cancelSkillUpload();
        throw new Error(`Error attempting to prepare signed url with Type[${fileType}] and Extension[${extension}]`);
      }
    });
  }

  get chunkCount(): number {
    if (this.newFile) {
      return Math.ceil(this.newFile.size / environment.uploadChunkSize);
    }

    return 0;
  }

  public addSkillFile(evt: Event) {
    const signature = className + ".addSkillFile: ";
    if (!hasKey(evt, 'target')) {
      alert("There was an issue with the file you attempted to select. Please try again.");
      throw new Error("Evt did not contain target");
    }

    if (!hasKey(evt.target, 'files')) {
      alert("There was an issue with the file you attempted to select. Please try again.");
      throw new Error("Evt.target did not contain files");
    }

    const fileList = evt.target.files as File[];

    logger.debug(signature, fileList);

    if (fileList.length === 0) {
      this.cancelSkillUpload();
      return;
    }

    try {
      if (fileList.length) {
        this.newFile = null;
        for (let element of fileList) {
          this.selectFile(element);
        }
      }
    } catch (e) {
      this.cancelSkillUpload();
    }

  }
  //#endregion

  public sortSubCategory(stringArray: { value: string, text: string, }[]) {
    let sortedArray: { value: string, text: string, }[] = stringArray.sort((n1, n2) => {
      if (n1.text > n2.text) {
        return 1;
      }

      if (n1.text < n2.text) {
        return -1;
      }

      return 0;
    });
    return sortedArray;
  }


  public cancelCurrentSkill(i: number) {
    this.skillFiles = this.skillFiles.filter((fl: File) => {
      return fl.name != this.skillFiles[i].name;
    });
  }

  public removeUserFile(userFileId: number) {
    this.__userFileService.archiveUserFile(userFileId).subscribe({
      next: () => {
        // this.loadUserFiles();
        // this.reloadGrid.emit();
        this.skillFiles = this.skillFiles.filter((fl: any) => {
          return fl.id != userFileId;
        });
        alert("Deleted File");
      },
      error: () => {
        alert("Error Deleting File. Please try again.");
        throw new Error(`Error Deleting UserFile[${userFileId}]`);
      }
    })
  }

  public loadUserFiles() {
    this.__userFileService.listUserFiles().subscribe({
      next: result => {
        this.skillFiles = result.data.rows;
      }
    });
  }

  public downloadUserFile(userFileId: number) {
    let signature = className + ".viewUserFile: ";
    if (!userFileId) {
      logger.error(signature + `User attempted to view UserFile[${userFileId}] which was not found in the resultset`);
      throw new Error("Invalid User File");
    }

    this.__userFileService.getSignedUrl(userFileId).subscribe({
      next: (result) => {
        if (result.status && result.data && result.data.url) {
          window.open(result.data.url, "_blank");
        } else {
          alert("There was an error downloading the File. Support has been notified");
          throw new Error(`Error downloading userFile[${userFileId}]`);
        }
      },
      error: () => {
        alert("Error Deleting File. Please try again.");
        throw new Error(`Error Deleting UserFile[${userFileId}]`);
      }
    })
  }

  /**
   * @description Handles updates to the subcategory field in the form. Updating which main categories can be selected by the user. Marks the first as selected if the length is exactly 1
   */
  public monitorSkillSelection(): void {
    this.f['sub_category'].valueChanges.subscribe({
      next: val => {
        if (!val) {
          this.mainCategories = [];
        } else {
          this.mainCategories = categoryData.filter(category => {
            return !!category.children.find(child => child.value === val);
          });
        }

        if (this.mainCategories.length > 0) {
          this.f['category'].setValue(this.mainCategories[0].value);
        } else {
          this.f['category'].setValue(null);
        }
      }
    });
  }


  public deleteSkill() {
    if (!this.activeGridItem || !this.activeGridItem.id) {
      throw new Error("Unable to identify target grid for new skill");
    }

    if (!this.activeGridItem.skill || !this.activeGridItem.skill.id) {
      throw new Error("Unable to identify target skill for edit");
    }

    /* validateForm({
      form: this.skillForm,
      onError: () => {
      }
    }); */

    this.__skillService.userSkillDelete({ id: Number(this.activeGridItem.skill.id) }).subscribe(
      res => {
        this.deleteDraft();

        if (this.activeGridItem && res.data) {
          this.activeGridItem.skill = res.data.skill;
        }

        this.handleOverlayClick();
        this.reloadGrid.emit();
      },
      (err) => {
        const message = "Unknown Error";

        if (err instanceof WellFormedError) {
          alert(err.getMessage(message));
          throw err;
        }

        alert(message);
        console.error(JSON.stringify(err));
        throw new Error(message);
      });
  }

  getYears(): number[] {
    const currentYear = new Date().getFullYear();
    const startYear = 2018;
    const years = [];

    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }

    return years;
  }
}
