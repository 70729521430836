import { IUserSkill } from "src/app/model/user-skill.model";

// This should be moved to /model if it is turned into a service and db updated to support this
export type IUserGridItem = {
  id: number,
  guid: string,
  skill?: IUserSkill,
  // Used to store the original skill data when the skill property is held by a 'draft' skill
  originalSkill?: IUserSkill
}

/**
 * @description Static representation of a theoretical userGrid model that would be returned by the api if at any point there is an intent to change the grids on a per-user basis.
 *  Creats a static grid item count
 */
 export const userGridItems: IUserGridItem[] = [
  {
    "id": 1,
    "guid": "1732de4a-d799-4955-adf5-74a9a7896178"
  },
  {
    "id": 2,
    "guid": "79d6e712-f29b-4a3d-8a24-c74686a060de"
  },
  {
    "id": 3,
    "guid": "8dd3b01f-2267-4ddf-bf74-3037e40de1b4"
  },
  {
    "id": 4,
    "guid": "c59b0b76-ef87-49a7-a856-ea21a20a9eee"
  },
  {
    "id": 5,
    "guid": "1103b840-1a8b-4c8d-9049-1842bac58537"
  },
  {
    "id": 6,
    "guid": "6d61b720-04b0-4f58-b986-c4e35ed6df73"
  },
  {
    "id": 7,
    "guid": "652109aa-4d36-41cb-8547-cf952c38d671"
  },
  {
    "id": 8,
    "guid": "40374499-a4e9-480f-923e-9e0bb7103710"
  },
  {
    "id": 9,
    "guid": "5b35e87b-7746-4bcd-acea-7eec818a3751"
  },
  {
    "id": 10,
    "guid": "a57b451f-21a0-4c17-93c0-bde0ead0a8a0"
  },
  {
    "id": 11,
    "guid": "e90feb06-dc57-4c06-87bf-caa314e01e61"
  },
  {
    "id": 12,
    "guid": "9980b2ce-6bb2-4a8f-8342-c74805872a92"
  },
  {
    "id": 13,
    "guid": "8998f817-e208-4901-905d-8cd25da5c4bb"
  },
  {
    "id": 14,
    "guid": "170bad92-5c19-49f6-8740-1aeb67680832",
  },
  {
    "id": 15,
    "guid": "512b1da2-6b20-4784-a76e-408f4e2e6de6",
  },
  {
    "id": 16,
    "guid": "8482ae9a-ec99-4984-85d8-87bbbd47c515",
  },
  {
    "id": 17,
    "guid": "40bdb1a9-7a28-44ba-b6b5-f5af909b48d8",
  },
  {
    "id": 18,
    "guid": "effb2fc4-3ffe-48c0-bfd6-650a3e98168d",
  },
  {
    "id": 19,
    "guid": "6cdf0228-bd0c-48b2-9f8c-46fd58027490",
  },
  {
    "id": 20,
    "guid": "25d1610d-4f9a-43a9-9dac-b96af3085ef9",
  },
  {
    "id": 21,
    "guid": "bee85f14-2ba3-49d1-af40-190daa05d478"
  },
  {
    "id": 22,
    "guid": "c77a5629-7f91-4750-bf31-268170e50f0d"
  },
  {
    "id": 23,
    "guid": "80e16981-3527-4822-b667-1db41ea74ff5"
  },
  {
    "id": 24,
    "guid": "b41fbe71-aab9-4af1-8c77-8a3c698ba1c6"
  },
  {
    "id": 25,
    "guid": "5fcabf9c-bd75-493d-8120-1ad8ea49055b"
  },
  {
    "id": 26,
    "guid": "5e4c0352-d4f9-451d-b946-704690997da1"
  },
  {
    "id": 27,
    "guid": "37bc3896-3948-4c44-b79d-ca0ba9a82bfe"
  },
  {
    "id": 28,
    "guid": "01032783-5439-4b96-8048-6b0d4f4967c7",
  },
  {
    "id": 29,
    "guid": "09fceec8-8268-4b93-87ae-66412ae7a0de"
  },
  {
    "id": 30,
    "guid": "e7ff3e1f-a9c2-4560-931c-f1fbefca2285",
  },
  {
    "id": 31,
    "guid": "6bcabad4-9287-4c19-9398-2b0dde88cad1"
  },
  {
    "id": 32,
    "guid": "0db8861b-18f9-4c7e-99ed-758f4af5b128"
  },
  {
    "id": 33,
    "guid": "b8cb6997-6feb-4029-80b0-72fcf5f480d6"
  },
  {
    "id": 34,
    "guid": "98f497c2-1648-41c1-9c29-a9251a0671d3"
  },
  {
    "id": 35,
    "guid": "5564401f-7526-49ee-9770-109d1c11f591"
  },
  {
    "id": 36,
    "guid": "f31f0ed8-9564-4070-8dcf-247554e7b29e"
  },
  {
    "id": 37,
    "guid": "f7833282-22a0-47cb-993b-badc3970d231"
  }
];