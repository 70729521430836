import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SecureService } from 'src/app/service/secure.service';
import { UserAuthService } from 'src/app/service/user-auth.service';
import { logger } from 'src/app/util/logger.util';

const className = "LoginLayoutComponent";

@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.scss']
})
export class LoginLayoutComponent {

  public currentUrl: string = "";

  constructor(
    private router: Router
  ) {
    this.currentUrl = router.url;
    const signature = className + ".constructor: ";
    logger.silly(signature, this.currentUrl);
  }
}
