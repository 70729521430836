import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { JwtService } from 'src/app/service/jwt.service';
import { hasKey } from '../util/object.util';
import { WellFormedError } from '../model/error.model';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    public __jwtService: JwtService,
    private __router: Router,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(err => {
        if ([401, 403].indexOf(err.status) !== -1) {
          if (this.__router.url.match(/^\/admin/)) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            this.__jwtService.removeJWTData();

            this.__router.navigate(['/admin']);
          }
        }

        if (hasKey(err, 'error') && hasKey(err.error, 'statusCode') && typeof err.error.statusCode === 'number' && hasKey(err.error, 'message') && typeof err.error.message === 'string') {
          return throwError(() => new WellFormedError(err.error as Partial<WellFormedError>));
        }

        return throwError(() => err);
      }
      ))
  }
}
