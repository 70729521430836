<div class="footer-content text-center">
  <div class="container">
    <div class="overflow-hidden">
    <ul>
      <li><a href="#">© Genesis Christian College 2022</a></li>
      <li><a href="#">Privacy & Policies</a></li>
      <li><a href="#">Website by WK Digital + NWO</a></li>
    </ul>
  </div>
</div>
</div>
