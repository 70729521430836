import { Injectable } from '@angular/core';
import { CookiesStorageService } from 'ngx-store';
import jwt_decode from 'jwt-decode';
import { IJWTPayload, IJWTPayloadDecoded } from '../model/auth.model';
import { logger } from '../util/logger.util';

export enum StorageServiceKey {
    jwt = 'jwt',
    jwt_refresh = 'jsw_refresh',
    jwt_token_type = 'jwt_token_type',
}

const className = "JwtService";

@Injectable()
export class JwtService {
    constructor(public storageService: CookiesStorageService) {
    }

    readonly saveJWTData = (jwtPayload: IJWTPayload): boolean => {
        const signature = className + ".saveJWTData: ";
        /**
         * Verify jwt before saving
         */
        const payload = this.verifyJWT(jwtPayload.accessToken);

        if (!payload) {
            logger.silly(signature, "Jwt did not pass verification and was rejected");
            return false;
        }

        logger.silly(signature, "Writing JWT Data");
        this.storageService.set(StorageServiceKey.jwt, jwtPayload.accessToken);
        this.storageService.set(StorageServiceKey.jwt_refresh, jwtPayload.refreshToken);
        this.storageService.set(StorageServiceKey.jwt_token_type, jwtPayload.tokenType);

        return true;
    };

    readonly getJWTString = (): string => {
        return this.storageService.get(StorageServiceKey.jwt) || ''
    };

    readonly getJWTRefreshString = (): string => {
        return this.storageService.get(StorageServiceKey.jwt_refresh) || ''
    };

    readonly getJWTTypeString = (): string => {
        return this.storageService.get(StorageServiceKey.jwt_token_type) || ''
    };

    readonly decodeJWT = (token?: string): IJWTPayloadDecoded | null => {
        const jwt: string = token || this.storageService.get(StorageServiceKey.jwt);
        let payload: IJWTPayloadDecoded;

        try {
            payload = jwt_decode(jwt);
        } catch (e) {
            return null;
        }

        if (!payload) {
            return null;
        }

        return payload;
    };

    readonly verifyJWT = (token?: string | IJWTPayloadDecoded): boolean => {
        const payload = token ? typeof token === 'string' && this.decodeJWT(token) || (token as IJWTPayloadDecoded) : this.decodeJWT();

        if (!payload) {
            return false;
        }

        if (payload.exp < Math.round(new Date().getTime() / 1000)) {
            return false;
        }

        return true;
    };

    readonly isNeedRefreshJWT = (token?: string): boolean => {
        const payload = this.decodeJWT(token);

        if (!payload) {
            return false;
        }

        if (payload.exp < new Date().getTime()) {
            return true;
        }

        return false;
    };

    readonly removeJWTData = () => {
        this.storageService.remove(StorageServiceKey.jwt);
        this.storageService.remove(StorageServiceKey.jwt_refresh);
        this.storageService.remove(StorageServiceKey.jwt_token_type);
    };

    readonly isExpired = (token?: string | IJWTPayloadDecoded): boolean => {
        const payload = token ? typeof token === 'string' && this.decodeJWT(token) || (token as IJWTPayloadDecoded) : this.decodeJWT();

        if (!payload) {
            return false;
        }

        const now = new Date();
        const nowSeconds = Math.round(now.getTime() / 1000);

        if (payload.exp < nowSeconds) {
            return true;
        }

        return false;
    };
}
