<div class="main-content user-content">
    <div class="container container-small">
        <div class="heading text-theme text-center">
            <h1>Add new user</h1>
        </div>
        <div class="overflow-hidden">
            <form [formGroup]="registerForm">
                <div class="row g-40">
                    <div class="col-md-6">
                        <label class="label-custom" for="f-name">First Name</label>
                        <input type="text" id="f-name" formControlName="firstname" class="form-control form-custom"
                            value="William" [ngClass]="{ 'is-invalid': submitted && hasError('firstname') }">
                        <div *ngIf="submitted && hasError('firstname')" class="invalid-feedback">
                            <div *ngIf="hasError('firstname','required')">First name is required</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="label-custom" for="middlename">Middle Name</label>
                        <input type="text" id="m-name" formControlName="middlename" class="form-control form-custom"
                            value="Theodore" [ngClass]="{ 'is-invalid': submitted && hasError('middlename') }">
                        <div *ngIf="submitted && hasError('middlename')" class="invalid-feedback">
                            <div *ngIf="hasError('middlename','required')">Middle name is required</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="label-custom" for="lastname">last Name</label>
                        <input type="text" id="l-name" formControlName="lastname" class="form-control form-custom"
                            value="Young" [ngClass]="{ 'is-invalid': submitted && hasError('lastname') }">
                        <div *ngIf="submitted && hasError('lastname')" class="invalid-feedback">
                            <div *ngIf="hasError('lastname','required')">Last name is required</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="label-custom" for="phone_number">Phone Number</label>
                        <input type="text" id="phone_number" formControlName="phone_number"
                            class="form-control form-custom"
                            [ngClass]="{ 'is-invalid': submitted && hasError('phone_number') }">
                        <div *ngIf="submitted && hasError('phone_number')" class="invalid-feedback">
                            <div *ngIf="hasError('phone_number','required')">Phone Number is required</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="label-custom" for="email">Email</label>
                        <input type="email" id="email" formControlName="email" [email]="true"
                            class="form-control form-custom" value="william.young@gmail.com"
                            [ngClass]="{ 'is-invalid': submitted && hasError('email') }">
                        <div *ngIf="submitted && hasError('email')" class="invalid-feedback">
                            <div *ngIf="hasError('email','required')">Email is required</div>
                            <div *ngIf="hasError('email','email')">Email is not valid</div>
                        </div>
                    </div>
                    <div class="col-md-6" *ngIf="f['role'].value === 0">
                        <label class="label-custom" for="dob_string">Date of birth</label>
                        <input type="text" id="dob" class="form-control form-custom" formControlName="dob_string"
                            value="{{minDate}}" placeholder="" bsDatepicker [maxDate]="maxDate" [(ngModel)]="bsValue"
                            [bsConfig]="{dateInputFormat: 'D/MM/YYYY' ,containerClass: 'theme-blue',showWeekNumbers: false }"
                            [ngClass]="{ 'is-invalid': submitted && hasError('dob_string') }">
                        <div *ngIf="submitted && hasError('dob_string')" class="invalid-feedback">
                            <div *ngIf="hasError('dob_string','required')">Date of birth is required</div>
                        </div>
                    </div>
                    <div class="col-md-6" *ngIf="f['role'].value === 0">
                        <label class="label-custom" for="g-class">Graduating class</label>
                        <div class="styled-select">
                            <select class="form-control form-custom" id="g-class" formControlName="graduting_class"
                                [ngClass]="{ 'is-invalid': submitted && hasError('graduting_class') }">
                                <option *ngFor="let opt of options" [selected]="opt === f['graduting_class'].value"
                                    [value]="opt">
                                    {{ opt }}
                                </option>
                            </select>
                            <div *ngIf="submitted && hasError('graduting_class')" class="invalid-feedback">
                                <div *ngIf="hasError('graduting_class','required')">Graduating class is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--
                    <div class="col-md-6">
                        <label class="label-custom" for="linkedin_url">Linkedin url</label>
                        <input type="url" url="true" id="linkedin_url" formControlName="linkedin_url"
                            class="form-control form-custom"
                            [ngClass]="{ 'is-invalid': submitted && hasError('linkedin_url') }">
                        <div *ngIf="submitted && hasError('linkedin_url')" class="invalid-feedback">
                            <div *ngIf="hasError('linkedin_url','required')">Linkedin url is required</div>
                            <div *ngIf="hasError('linkedin_url','url')">Linkedin url not valid</div>
                        </div>
                    </div>
                    -->
                </div>
                <div class="row mt-4">
                    <div class="col-md-6">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" formControlName="role" id="inlineRadio1"
                                [value]="0" (click)="clickRoleOption(0)">
                            <label class="label-custom" class="form-check-label" for="inlineRadio1">Student</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" formControlName="role" id="inlineRadio2"
                                [value]="1" (click)="clickRoleOption(1)">
                            <label class="label-custom" class="form-check-label"
                                for="inlineRadio2">Administrator</label>
                        </div>
                    </div>
                    <div class="col-md-6 text-end">
                        <button class="btn btn-theme" (click)="saveFunction()">Save</button>
                        <a [routerLink]="['/admin']" class="btn btn-theme1">Cancel</a>
                    </div>
                </div>
            </form>
        </div>
        <div class="user-popup" *ngIf="popup">
            <p>New {{roleName.toLowerCase()}} <span>{{f['firstname'].value}} {{f['lastname'].value}}</span> has been
                created. They will
                shortly receive an invitation to login
                to the portal and set their password.</p>
        </div>
    </div>
</div>