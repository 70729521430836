<section class="create-profile">
    <div class="container">
        <div class="row cps cps-7 emailVerifyBlock" *ngIf="sendingVerificationBlock">
            <div class="col-lg-7 cp-box mx-auto">
                <p class="m-0">Sending your verification. Please Wait.
                </p>
            </div>
        </div>

        <div class="row cps cps-7 emailVerifyBlock" *ngIf="errorVerifyingBlock">
            <div class="col-lg-7 cp-box mx-auto">
                <p class="m-0">Your verification was missing some detail. You can resend the verification email from your application.</p>
            </div>
        </div>

        <div class="row cps cps-7 emailVerifyBlock" *ngIf="successVerifyingBlock">
            <div class="col-lg-7 cp-box mx-auto">
                <p class="m-0">Your verification was completed. You may now close this window.</p>
            </div>
        </div>
    </div>
</section>