import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { createUrl } from '../util/api.util';
import { SecureService } from './secure.service';
import { IQueryFilter } from '../model/query.filter.class';

@Injectable({
  providedIn: 'root'
})
export class UserFileService {

  constructor(
    private __http: HttpClient,
    private __secureService: SecureService
  ) { }

  /**
   * @description currently authenticated user all related datas
   * @returns 
   */
  public requestSignedUrl(mimeType: string, extension: string, chunkCount: number, name: string, userSkillId: number) {
    return this.__http.post<{
      status: boolean,
      message: string,
      userSkillId: number,
      data?: {
        parts: string[],
        id: number,
      }
    }>(createUrl('user_file', 'upload'), {
      mimeType,
      extension,
      chunkCount,
      name,
      userSkillId,
    });
  }

  /**
   * @description currently authenticated user all related datas
   * @param {number} uploadId The ID of the upload
   * @returns 
   */
  public notifyUploadComplete(uploadId: number, parts: { ETag: string, PartNumber: number }[]) {
    return this.__http.post(createUrl('user_file', 'complete'), { id: uploadId, parts });
  }

  /**
   * @description Fetches a list of user files by the supplied fileter
   */
  public listUserFiles(filter?: Partial<IQueryFilter>) {
    return this.__http.post<{
      status: boolean,
      message: string,
      data: {
        count: number,
        rows: {
          id: number,
          name: string | null,
          url: string | null,
        }[]
      }
    }>(createUrl('user_file', 'list'), filter);
  }

  /**
   * @description Performs a soft delete on a userFile
   * @param {number} userFileId 
   * @returns 
   */
  public archiveUserFile(userFileId: number) {
    return this.__http.delete(createUrl('user_file', userFileId));
  }

  /**
   * @description Create a manually defined user file
   */
  public createUserFile(data: { url: string }) {
    return this.__http.post(createUrl('user_file'), data);
  }

  /**
   * @description Create a manually defined user file
   */
  public getSignedUrl(userFileId: number) {
    return this.__http.get<{
      status: boolean,
      message: string,
      data?: {
        url: string
      }
    }>(createUrl('user_file', 'download', userFileId));
  }

  /**
   * @description Create a manually defined user file
   */
   public getSignedUrlMultiple(ids: string) {
    return this.__http.post(createUrl('user_file', 'download_multiple'), {ids});
  }
}
