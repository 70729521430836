import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserAuthService } from '../service/user-auth.service';
import { logger } from '../util/logger.util';

const className = 'AuthGuard';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private __userAuthService: UserAuthService
  ) { }

  async canActivate() {
    const signature = className + '.canActivate: ';
    const currentUser = this.__userAuthService.currentUserSubject.getValue();
    let result = currentUser !== null;

    if( !result ) {
      logger.silly(signature + `Attempting to fetch current user`);
      await this.__userAuthService.getCurrentUser().toPromise();
      result = this.__userAuthService.currentUserSubject.getValue() !== null;
    }

    logger.silly(signature + `End Result[${result}]`);
    return result;
  }
}
