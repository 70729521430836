import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CurrentUser } from '../model/user.model';
import { SkillService } from '../service/skill.service';
import { UserAuthService } from '../service/user-auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  showPrint = true;

  loginUser: CurrentUser | null = null;

  get userName(): string {
    if (!this.loginUser) return '';

    return `${this.loginUser.firstname} ${this.loginUser.lastname}`;
  }

  get isAuthenticated(): boolean {
    return !!this.loginUser;
  }

  get isStudent(): boolean {
    return !!this.loginUser && this.loginUser.role === 0;
  }

  constructor(
    private __userAuthService: UserAuthService
  ) { }

  ngOnInit(): void {
    this.__userAuthService.currentUser.subscribe(user => {
      this.loginUser = user;
    });
  }

  logout(): void {
    this.__userAuthService.logout();
  }
}
