<div class="d-flex flex-column bg-theme text-themeLight min-vh-100">
    <div class="head-padding bg-white text-center">
      <img src="assets/images/site/login-logo.png" alt="Logo">
    </div>
    <div class="login-content flex-grow-1 text-center">
      <div class="container">
        <div class="py-83 mw-440 mx-auto">
          <h1 class="mb-1">Forgot Password</h1>
          <p class="mb-40">Reset your Password</p>
          <form [formGroup]="forgotForm" class="">
            <div class="form-floating floating-custom mb-20">
                <input type="email"
                    class="form-control form-custom {{(forgotForm.controls['email'].dirty && ( forgotForm.hasError('required', 'email') || forgotForm.hasError('email', 'email') ) )?'is-invalid':'is-valid'}}"
                    placeholder="Username" autocomplete="username" formControlName="email"
                    id="floatingInput" required>
                    <label class="label-custom" for="floatingInput">Email</label>
                <div class="invalid-feedback" *ngIf="forgotForm.controls['email'].dirty">
                    <small *ngIf="forgotForm.hasError('required', 'email')"
                        class="form-text text-danger validation-text">Email is required</small>
                    <small *ngIf="forgotForm.hasError('email', 'email')"
                        class="form-text text-danger validation-text">Email format is
                        wrong</small>
                </div>
            </div>
            <div class="input-group mb-4">
                <small *ngIf="forgotIncorrect"
                    class="form-text text-danger validation-text">{{forgotIncorrect_msg}}</small>
            </div>
            <div class="d-flex align-items-center justify-content-end">
                <button type="button" class="btn btn-white" id="loginBtn" (click)="reset()">
                  Reset Password
                </button>
                <button type="button" class="btn btn-danger" id="loginBtn" [routerLink]="['/user/login']">Cancel</button>
              </div>
        </form>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>