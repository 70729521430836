<div class="text-center">
  <div class="d-inline-block overflow-hidden">
    <div class="row g-50">
      <div class="col-auto">
        <div class="dargContent-main">
          <div class="dargContent-img">
            <img src="assets/images/site/work-ready.png" alt="Work Ready">
          </div>
          <h5 class="text-theme2">Work Ready</h5>
        </div>
      </div>
      <div class="col-auto">
        <div class="dargContent-main">
          <div class="dargContent-img">
            <img src="assets/images/site/century.png" alt="Century Skills and Beyond">
          </div>
          <h5 class="text-theme1">Future Ready Skills</h5>
        </div>
      </div>
      <div class="col-auto">
        <div class="dargContent-main">
          <div class="dargContent-img">
            <img src="assets/images/site/excellence.png" alt="Excellence">
          </div>
          <h5 class="text-theme">Excellence</h5>
        </div>
      </div>
      <div class="col-auto">
        <div class="dargContent-main">
          <div class="dargContent-img">
            <img src="assets/images/site/extra-mile.png" alt="The Extra Mile">
          </div>
          <h5 class="text-theme3">The Extra Mile</h5>
        </div>
      </div>
    </div>
  </div>
</div>
