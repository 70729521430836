export class WellFormedError extends Error {

  /**
   * @description Until all messages from the server have been fixed, this array contains
   *  a whitelistlist of acceptable messages from the server. When returning the stored message
   *  to the deveoper it MUST be checked against this list. This list must contain human readable
   *  messages
   */
  public static ApprovedMessages = [
    "Email address is already in use",
  ];

  /**
   * @description Default message that is acceptable for display to the user
   */
  public static DefaultMessage = "There was an error processing your data. The support team has been notified.";

  /**
   * @description statusCode if one is not supplied
   */
  public statusCode: number = 500;

  /**
   * @description Convenience function
   */
  private get approvedMessages() {
    return WellFormedError.ApprovedMessages
  }

  /**
   * @description Determines if the currently held message is an approved message
   */
  public get isApprovedMessage() {
    return this.approvedMessages.map(msg => msg.toLowerCase()).includes(this.message.toLowerCase());
  }

  /**
   * @description Provides a public interface for fetching either an approved message or the supplied default message
   * @param {string} defaultMessage 
   * @returns {string}
   */
  public getMessage(defaultMessage = WellFormedError.DefaultMessage) {
    if (this.isApprovedMessage) {
      return this.message;
    }

    return defaultMessage;
  }

  constructor(opts?: Partial<WellFormedError>) {
    super();

    this.statusCode = opts?.statusCode || this.statusCode;
    this.message = opts?.message || this.message;
  }
}