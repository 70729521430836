import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DropEvent } from 'ng-drag-drop';
import { LocalStorageService } from 'ngx-store';
import { categoryData } from 'src/app/model/skill-category.model';
import { UserAuthService } from 'src/app/service/user-auth.service';
import { logger } from 'src/app/util/logger.util';
import { IUserGridItem } from '../skill-grid/skill-grid-model';

const className = "SkillCategoryHexComponent";

@Component({
  selector: 'app-skill-category-hex',
  templateUrl: './skill-category-hex.component.html',
  styleUrls: ['./skill-category-hex.component.scss']
})
export class SkillCategoryHexComponent implements OnInit {

  private categories: typeof categoryData = categoryData;
  private userId: number | null = null;

  @Input()
  item: IUserGridItem | null = null;

  @Output() newDragEvent = new EventEmitter<{ from: IUserGridItem, to: number }>();

  constructor(
    private __localStorage: LocalStorageService,
    private __userAuthService: UserAuthService,
  ) { }

  ngOnInit(): void {
    this.loadDraftSkill();
    this.setCurrentUserId();
  }

  /**
   * @description Ensures the current userId is populated from the auth state to ensure drafts belong to the right user
   * @returns {void}
   */
  private setCurrentUserId() {
    this.__userAuthService.currentUser.subscribe(user => {
      this.userId = user && user.id
    });
  }

  /**
   * @description Displays the text associated to the subcategory of the skill in question (if applicable)
   */
  public getDisplayText(): string {
    if (!this.item || !this.item.skill) {
      return '';
    }
    const skill = this.item.skill;

    if (!skill.category) {
      return 'No Category';
    }

    const category = this.categories.find(category => category.value === skill.category);

    if (!category) {
      return 'No Category';
    }

    const subcategory = category.children.find(child => child.value === skill.sub_category);

    if (!subcategory) {
      return 'No Subcategory';
    }

    return subcategory.text.substring(0, 38)
  }

  /**
   * @description Loads a draft skill from localStorage for the selected grid item. This is deferred as the init will occur during rendering and will potentially change the state of the
   *  component mid render. Deferring this allows for the first pass of rendering to complete before the draft data is loaded
   */
  private loadDraftSkill(): void {
    setTimeout(() => {
      if (!this.item || !this.item.id) {
        throw new Error("Unable to identify target grid for skill drafts");
      }

      const signature = className + ".loadDraftSkill: ";
      const draftResource = this.__localStorage.load(this.getDraftStorageKey());
      const draftValue = draftResource.value || '';

      if (typeof draftValue === 'string' && draftValue.length) {
        try {
          const draftFormData = JSON.parse(draftValue);

          // Move the original data so it can be loaded if the draft is deleted
          if (this.item.skill) {
            this.item.originalSkill = this.item.skill
          }

          this.item.skill = {
            isDraft: true,
            ...draftFormData
          };
        } catch (e) {
          logger.error(signature + `Unable to parse DraftData[${String(draftValue)}]`);
          this.__localStorage.remove(this.getDraftStorageKey());
        }
      }
    }, 0);
  }

  private getDraftStorageKey(): string {
    return `skill_hex_${this.item && this.item.id || 0}_${this.userId}`;
  }

  onHexaDrop(old: DropEvent & { dragData: IUserGridItem }, current: number | undefined) {
    if (!current) {
      // There was no destination so do nothing
      return;
    }

    this.newDragEvent.emit({ from: old.dragData, to: current });
  }
}
