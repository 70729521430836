import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { filter, first, Observable } from 'rxjs';
import { WellFormedError } from 'src/app/model/error.model';
import { IUserSkill } from 'src/app/model/user-skill.model';
import { CurrentUser } from 'src/app/model/user.model';
import { SkillService } from 'src/app/service/skill.service';
import { UserAuthService } from 'src/app/service/user-auth.service';
import { logger } from 'src/app/util/logger.util';
import { SkillFormComponent } from '../skill-form/skill-form.component';
import { IUserGridItem, userGridItems } from '../skill-grid/skill-grid-model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';

const className = "SkillComponent";

@Component({
  selector: 'app-skill',
  templateUrl: './skill.component.html',
  styleUrls: ['./skill.component.scss']
})
export class SkillComponent implements OnInit {

  @ViewChild('skillForm') skillForm!: SkillFormComponent;
  @ViewChild('template', { static: true }) template!: TemplateRef<any>;

  // Passed to the grid form to indicate what is being edited
  activeGridItem: IUserGridItem | null = null;

  // Display grid for skills
  grids: IUserGridItem[] = this.freshGrid();

  // Details about the currently authenticated user
  loginUser: CurrentUser | null = null;

  // Display controls
  showSkillForm: boolean = false;

  // Modal
  modalRef?: BsModalRef;

  constructor(
    private __userAuthService: UserAuthService,
    private __skillService: SkillService,
    private modalService: BsModalService,
    private __route: ActivatedRoute,
    private __router: Router,
  ) {
  }

  ngOnInit(): void {
    const signature = className + `.ngOnInit: `;
    logger.silly(signature + "Init");
    this.__userAuthService.currentUser.pipe(
      filter(user => !!user),
      first()
    ).subscribe(user => {
      logger.silly(signature + `Current User Change`)
      if (user) {
        this.loginUser = user as CurrentUser | null;
        logger.silly(signature, user);

        this.listSkills().subscribe({
          next: () => {
            if ('addSkillOnOpen' in this.__route.snapshot.data) {
              logger.silly(signature + `Adding draft skill onOpen`);
              this.addDraft();
            } else if (!this.hasAnySkill() && !this.__skillService.hasSeenTutorial) {
              this.__skillService.hasSeenTutorial = true;
              this.openModal(this.template);
            }
          }
        });
      }
    });
  }

  ngOnDestroy() {
    const signature = className + ".ngOnDestroy: ";
    logger.silly(signature + "Destroy");
  }

  /**
   * @description Clones a fresh copy of the grid
   */
  private freshGrid(): IUserGridItem[] {
    return JSON.parse(JSON.stringify(userGridItems));
  }

  /**
   * @description Returns an observable which lists all skills and then notifies that it is complete;
   */
  public listSkills(): Observable<void> {
    return new Observable(sub => {

      const signature = className + ".listSkill: ";
      this.__skillService.userSkillListing({ page: 1, limit: 1000, search: "" }).subscribe({
        next: (res) => {
          this.grids = this.freshGrid();
          const skillData = res.data && res.data.rows || [];
          const orphanedSkills = [];

          for (let skill of skillData) {
            const targetGrid = this.grids.find(grid => grid.id === skill.hexa_sequence_number);

            if (targetGrid && !targetGrid.skill) {
              targetGrid.skill = skill;
            } else if (targetGrid && targetGrid.skill) {
              console.log(signature + `Found Conflicting TargetGrid[${targetGrid.id}] which is already occupied by Skill[${targetGrid.skill.id}] when placing Skill[${skill.id}]`);
              orphanedSkills.push(skill);
            } else {
              console.log(signature + `Unable to find TargetGrid[${skill.hexa_sequence_number}] for Skill[${skill.id}]`);
              orphanedSkills.push(skill);
            }
          }

          for (let skill of orphanedSkills) {
            const unusedGrid = this.grids.find(grid => !grid.skill);

            if (unusedGrid) {
              unusedGrid.skill = skill;
              unusedGrid.skill.isDraft = true;
              unusedGrid.skill.hexa_sequence_number = unusedGrid.id;
              console.log(signature + `Skill[${skill.id}] was put into draft mode on unused Grid[${skill.hexa_sequence_number}]`);
            }
          }

          logger.silly(signature + "Finished processing grid data");
          sub.next();
          sub.complete();
        },
        error: err => {
          const message = "Unknown Error";

          if (err instanceof WellFormedError) {
            alert(err.getMessage(message));
            throw err;
          }

          alert(message);
          console.error(JSON.stringify(err));

          sub.error(err);
          sub.complete();
        }
      });
    });
  }

  /**
   * @description Reliably determines if there is at least 1 skill on the grid to be displayed
   * @returns {boolean}
   */
  public hasAnySkill() {
    return !!this.grids.find(grid => !!grid.skill);
  }

  /**
   * @description Adds a blank draft skill to the grid at the first available slot
   */
  public addDraft() {
    const signature = className + ".openOrAddDraft: ";
    setTimeout(() => {
      const unusedGrid = this.grids.find(grid => !grid.skill);

      if (!unusedGrid) {
        throw new Error("Unable to determine an unused grid item for draft skill");
      }

      logger.silly(signature, `Opening Unused Grid[${unusedGrid.id}]`);
      this.handleGridItemClick(unusedGrid);
    }, 500);
  }

  /**
   * @description Activates the edit window when a grid item is clicked
   */
  public handleGridItemClick(userGridItem: IUserGridItem) {
    const signature = className + ".handleGridItemClick: ";
    this.activeGridItem = userGridItem;
    this.showSkillForm = !this.showSkillForm;
    this.skillForm.refreshForm();
    logger.silly(signature + `Updating showSkillForm[${this.showSkillForm}]`);
  }

  /**
   * @description Launches a modal
   * @param template 
   */
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      keyboard: true,
      ignoreBackdropClick: false,
      class: 'modal-lg'
    });
  }

  handleSkillFormActiveChange(val: boolean) {
    const signature = className + ".handleSkillFormActiveChange: ";
    if( !val && 'addSkillOnOpen' in this.__route.snapshot.data ) {
      logger.info(signature + 'Updating route');
      this.__router.navigateByUrl('/home/skill');
    }
  }
}
