import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { WellFormedError } from 'src/app/model/error.model';
import { IUserSkill } from 'src/app/model/user-skill.model';
import { SkillService } from 'src/app/service/skill.service';
import { UserAuthService } from 'src/app/service/user-auth.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { validateForm } from 'src/app/util/form.util';
import { jsPDF } from "jspdf";
import { logger } from 'src/app/util/logger.util';
import { CurrentUser } from 'src/app/model/user.model';
import html2canvas from 'html2canvas';
import { categoryData } from 'src/app/model/skill-category.model';
import { UserFileService } from 'src/app/service/user-file.service';
import * as JSZip from 'jszip';
const JSZipUtils = require('jszip-utils');

const className = "PrintSkillComponent";

@Component({
  selector: 'app-print-skill',
  templateUrl: './print-skill.component.html',
  styleUrls: ['./print-skill.component.scss']
})
export class PrintSkillComponent implements OnInit {

  private categories: typeof categoryData = categoryData;

  Work: IUserSkill[] = [];
  future: IUserSkill[] = [];
  excellence: IUserSkill[] = [];
  extra_mile: IUserSkill[] = [];
  public skillData: IUserSkill[] = [];
  loginUser: CurrentUser | null = null;
  modalRef?: BsModalRef;
  public printForm: FormGroup;

  // Sets page size for print modes
  public printMode: boolean = false;

  @ViewChild('content') content!: ElementRef;
  @ViewChild('printContent', {}) printContent!: ElementRef<HTMLDivElement>;

  constructor(
    private __skillService: SkillService,
    private __userAuthService: UserAuthService,
    private modalService: BsModalService,
    private __formBuilder: FormBuilder,
    private __userFileService: UserFileService,
  ) {

    this.printForm = this.__formBuilder.group({
      skills: this.__formBuilder.array([], [Validators.required]),
      downloadSkill: ['', []],
    });
  }

  /**
   * @description Displays the text associated to the subcategory of the skill in question (if applicable)
   */
  public getDisplayText(skill: IUserSkill): string {
    if (!skill) {
      return '';
    }

    if (!skill.category) {
      return 'No Category';
    }

    const category = this.categories.find(category => category.value === skill.category);

    if (!category) {
      return 'No Category';
    }

    const subcategory = category.children.find(child => child.value === skill.sub_category);

    if (!subcategory) {
      return 'No Subcategory';
    }

    return subcategory.text.substring(0, 38)
  }

  ngOnInit(): void {
    this.listSkill();
    this.__userAuthService.currentUser.subscribe(user => {
      if (user) {
        this.loginUser = user;
      }
    });
  }

  /**
   * @description Takes the incoming skill list and divides them into appropriate variables for rendering and printing
   */
  public sortSkills(skills: IUserSkill[]) {
    const signature = className + ".sortSkills: ";
    const excellence: typeof this.excellence = [];
    const Work: typeof this.Work = [];
    const extra_mile: typeof this.extra_mile = [];
    const future: typeof this.future = [];

    for (let itm of skills) {
      switch (itm.category) {
        case `excellence`:
          excellence.push(itm);
          break;
        case `work_ready`:
          Work.push(itm);
          break;
        case `the_extra_mile`:
          extra_mile.push(itm);
          break;
        case `future_ready_skills`:
          future.push(itm);
          break;
        default:
          logger.warn(signature + `Dont know what to do with Category[${itm.category}]`);
          break;
      }
    }

    this.excellence = this.sortSubCategory(excellence);
    this.Work = this.sortSubCategory(Work);
    this.extra_mile = this.sortSubCategory(extra_mile);
    this.future = this.sortSubCategory(future);

    logger.info(signature + `Sorted Excellence[${this.excellence.length}] WorkReady[${this.Work.length}] ExtraMile[${this.extra_mile.length}] FutureReady[${this.future.length}] skills`);
  }

  public sortSubCategory(stringArray: IUserSkill[]) {
    let sortedArray: IUserSkill[] = stringArray.sort((n1, n2) => {
      
      if (n1.year_completed < n2.year_completed) {
        return 1;
      }

      if (n1.year_completed > n2.year_completed) {
        return -1;
      }

      if (n1.sub_category > n2.sub_category) {
        return 1;
      }

      if (n1.sub_category < n2.sub_category) {
        return -1;
      }

      return 0;
    });

    return sortedArray;
  }


  public listSkill() {
    this.__skillService.userSkillListing({ page: 1, limit: 1000, search: "" }).subscribe({
      next: (res: any) => {
        this.printForm.reset();
        this.skillData = res.data.rows;

        const skills: FormArray = this.printForm.get('skills') as FormArray;

        // Automatically check every skill
        this.skillData.forEach(skill => {
          skills.push(new FormControl(skill.id));
        });

        this.sortSubCategory(res.data.rows);
        this.sortSkills(res.data.rows);
      },
      error: (err) => {
        const message = "Unknown Error";

        if (err instanceof WellFormedError) {
          alert(err.getMessage(message));
          throw err;
        }

        alert(message);
        console.error(JSON.stringify(err));
        throw new Error(message);
      }
    })

  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      keyboard: false,
      ignoreBackdropClick: true,
      class: 'modal-lg'
    });
  }

  onCheckboxChange(e: any) {
    const skills: FormArray = this.printForm.get('skills') as FormArray;
    if (e.target.checked) {
      skills.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      skills.controls.forEach((item: any) => {
        if (item.value == e.target.value) {
          skills.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  /**
   * @description Determines if the indiciated skill is currently selected for print
   * @param {number} skillId 
   * @returns {boolean}
   */
  public isChecked(skillId: number): boolean {
    const targetSkills: number[] = (this.printForm.value.skills || []).map((skillIdStr: string) => Number(skillIdStr));

    return targetSkills.includes(skillId);
  }

  public async printSelcetedSkill() {
    const signature = className + ".printSelcetedSkill: ";

    validateForm({
      form: this.printForm,
      onError: () => {
        logger.error(signature + "Error");
      }
    });

    const targetSkills: number[] = (this.printForm.value.skills || []).map((skillIdStr: string) => Number(skillIdStr));
    const filteredSkills = this.skillData.filter(skill => targetSkills.includes(skill.id));
    this.sortSkills(filteredSkills);
    this.modalRef?.hide();

    if (this.printForm.value.downloadSkill) {
      this.__userFileService.getSignedUrlMultiple(targetSkills.toString()).subscribe(
        (res: any) => {
          this.downloadAsZip(res.data.urls);
        }
      );
    }

    this.printMode = true;
    setTimeout(async () => {
      const canvasElement = await html2canvas(this.printContent.nativeElement, {
        allowTaint: true
      });
      const img = canvasElement.toDataURL("image/png");
      const doc = new jsPDF('p', 'px', 'a4');
      const width = doc.internal.pageSize.getWidth();
      const height = doc.internal.pageSize.getHeight();
      const widthFactor = canvasElement.width / width;
      const heightFactor = canvasElement.height / height;
      const factorMax = Math.max(widthFactor, heightFactor);

      var now = new Date();
      var year = now.getFullYear();
      var month = now.getMonth() + 1;
      var day = now.getDate();
      var hour = now.getHours() + 1;
      var minute = now.getMinutes();

      const fileName = `${this.loginUser?.firstname}_${this.loginUser?.lastname}_${year}_${month}_${day}_${hour}_${minute}`;

      doc.addImage(img, 'PNG', 0, 0, canvasElement.width / factorMax, canvasElement.height / factorMax);
      doc.save(fileName + ".pdf");
      this.printMode = false;
    });
  }

  public downloadAsZip(urls: any[]): void {
    const signature = className + ".downloadAsZip: ";
    let count = 0;
    const zip = new JSZip();
    const uniqueFileNames: string[] = [];
    let zipName = `${this.loginUser?.firstname}_${this.loginUser?.lastname}_skills_attachments`;

    urls.forEach((uf) => {
      let filename = uf.originalname;

      if (uniqueFileNames.includes(filename)) {
        logger.warn(signature + `Ignoring duplicate FileName[${filename}]`);
        return;
      }

      JSZipUtils.getBinaryContent(uf.url, (err: any, data: any) => {
        if (err) {
          throw err;
        }

        zip.file(filename, data, { binary: true });
        count++;

        if (count === urls.length) {
          zip.generateAsync({ type: 'blob' }).then((content) => {
            const objectUrl: string = URL.createObjectURL(content);
            const link: any = document.createElement('a');
            link.download = zipName;
            link.href = objectUrl;
            link.click();
          });
        }
      });
    });
  }
}
