import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultLayoutComponent } from './layout/default-layout/default-layout.component';
import { LoginLayoutComponent } from './layout/login-layout/login-layout.component';
import { UserLoginComponent } from './user-login/user-login.component';
import { UserVerifyComponent } from './user-verify/user-verify.component';
import { NotFoundComponent } from './common/not-found/not-found.component';
import { AuthGuard } from './guard/auth.guard';
import { AdminAuthGuard } from './guard/admin-auth.guard';
import { SkillComponent } from './components/user/skill/skill.component';
import { UserListComponent } from './components/admin/user-list/user-list.component';
import { UserAddComponent } from './components/admin/user-add/user-add.component';
import { PrintSkillComponent } from './components/user/print-skill/print-skill.component';
import { ResetComponent } from './components/user/reset/reset.component';
import { ForgotComponent } from './forgot/forgot.component';
import { UserEditComponent } from './components/admin/user-edit/user-edit.component';


export const routes: Routes = [
  {
    path: '',
    redirectTo: 'user',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: '',
        redirectTo: 'skill',
        pathMatch: "full",
      },
      {
        path: 'skill/add',
        component: SkillComponent,
        canActivate: [AuthGuard],
        data: {
          addSkillOnOpen: true,
          title: 'skills'
        },
      },
      {
        path: 'skill',
        component: SkillComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'skills'
        },
      },
      {
        path: 'print',
        component: PrintSkillComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'skills-print'
        },
      },
    ]
  },
  {
    path: 'user',
    component: LoginLayoutComponent,
    data: {
      title: 'User'
    },
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: "full",
      },
      {
        path: 'reset/:token',
        component: ResetComponent,
      },
      {
        path: 'login',
        component: UserLoginComponent,
        data: {
          title: 'Login'
        },
      },
      {
        path: 'verify',
        component: UserVerifyComponent,
        data: {
          title: 'Verify'
        },
      },
      {
        path: 'forgot-password',
        component: ForgotComponent,
        data: {
          title: 'Forgot password'
        },
      },
    ]
  },
  {
    path: 'admin',
    component: DefaultLayoutComponent,
    data: {
      title: 'Admin'
    },
    children: [
      {
        path: '',
        redirectTo: 'user-list',
        pathMatch: "full",
      },
      {
        path: 'user-list',
        component: UserListComponent,
        canActivate: [AdminAuthGuard],
        data: {
          title: 'user-list'
        },
      },
      {
        path: 'add-user',
        component: UserAddComponent,
        canActivate: [AdminAuthGuard],
        data: {
          title: 'Add User',
        },
      },
      {
        path: 'edit-user/:id',
        component: UserEditComponent,
        canActivate: [AdminAuthGuard],
        data: {
          title: 'Edit User',
        },
      },
    ]
  },
  { path: '**', component: NotFoundComponent }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
