import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotFoundComponent } from './common/not-found/not-found.component';
import { DefaultLayoutComponent } from './layout/default-layout/default-layout.component';
import { FormsModule, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { LoginLayoutComponent } from './layout/login-layout/login-layout.component';
import { CookiesStorageService, WebStorageModule } from 'ngx-store';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserLoginComponent } from './user-login/user-login.component';
import { SecureService } from './service/secure.service';
import { AuthService } from './service/auth.service';
import { JwtService } from './service/jwt.service';
import { UserAuthService } from './service/user-auth.service';
import { JwtInterceptor } from './interceptor/jwt.interceptor';
import { UserVerifyComponent } from './user-verify/user-verify.component';
import { Router } from '@angular/router';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { environment } from 'src/environments/environment';
import { ErrorInterceptor } from './interceptor/error.interceptor';
import { NumbersOnlyDirective } from './directive/numbersOnly.directive';
import { DebounceClickDirective } from './directive/debouceClick.directive';
import { SkillComponent } from './components/user/skill/skill.component';
import { FooterComponent } from './footer/footer.component';
import { SkillGridComponent } from './components/user/skill-grid/skill-grid.component';
import { SkillCategoryHexComponent } from './components/user/skill-category-hex/skill-category-hex.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgDragDropModule } from 'ng-drag-drop';
import { UserListComponent } from './components/admin/user-list/user-list.component';
import { UserAddComponent } from './components/admin/user-add/user-add.component';
import { PrintSkillComponent } from './components/user/print-skill/print-skill.component';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxPaginationModule } from 'ngx-pagination';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SkillCategorySelectComponent } from './components/user/skill-category-select/skill-category-select.component';
import { SkillLegendComponent } from './components/user/skill-legend/skill-legend.component';
import { SkillFormComponent } from './components/user/skill-form/skill-form.component';
import { ResetComponent } from './components/user/reset/reset.component';
import { ForgotComponent } from './forgot/forgot.component';
import { UserEditComponent } from './components/admin/user-edit/user-edit.component';
import { TooltipModule } from 'primeng/tooltip';
import { SortIconComponent } from './common/sort-icon/sort-icon.component';

declare const BUILD_ID: string;

Sentry.init({
  dsn: "https://d119e93baf914cafbfeef97fa2e09951@o135869.ingest.sentry.io/6572005",
  environment: environment.production ? 'Production' : 'Development',
  release: BUILD_ID,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    NotFoundComponent,
    DefaultLayoutComponent,
    LoginLayoutComponent,
    UserLoginComponent,
    UserVerifyComponent,
    NumbersOnlyDirective,
    DebounceClickDirective,
    SkillComponent,
    FooterComponent,
    SkillGridComponent,
    SkillFormComponent,
    SkillCategoryHexComponent,
    SkillCategorySelectComponent,
    SkillLegendComponent,
    UserListComponent,
    UserAddComponent,
    PrintSkillComponent,
    ResetComponent,
    ForgotComponent,
    UserEditComponent,
    SortIconComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PasswordStrengthMeterModule,
    WebStorageModule.forRoot(),
    NgSelectModule,
    NgDragDropModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgxPaginationModule,
    TooltipModule,
    ModalModule.forRoot()
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    CookiesStorageService,
    SecureService,
    AuthService,
    UserAuthService,
    JwtService,
    FormBuilder,
    BsDatepickerConfig
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
