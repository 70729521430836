import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../service/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { validateForm } from 'src/app/util/form.util';
import { SecureService } from 'src/app/service/secure.service';
import { ConfirmedValidator } from 'src/app/util/validators/confirmed.validator';
import { logger } from 'src/app/util/logger.util';
import { UserAuthService } from 'src/app/service/user-auth.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {
  title = 'Reset Form';

  public resetForm: FormGroup;
  public submitted: boolean = false;

  public resetFormCheck: boolean = true;
  public loginIncorrect: boolean = false;
  public loginIncorrect_msg: string = "";
  private resetData: Record<string, { token: string, email: string }> = {};

  constructor(
    private __formBuilder: FormBuilder,
    private __router: Router,
    private __activatedRoute: ActivatedRoute,
    private __secureService: SecureService,
    private __userAuthService: UserAuthService,
  ) {
    this.resetForm = this.__formBuilder.group({
      password: ['', [Validators.required]],
      c_password: ['', [Validators.required]],
    });
  }
  ngOnInit() {
    combineLatest([
      this.__activatedRoute.params,
      this.__activatedRoute.queryParams
    ]).subscribe(data => {
      let temp = Object.assign({}, ...data);
      this.resetData = Object.assign({}, ...data);
      this.resetData['resetToken'] = temp['token'];
    });
  }

  public resetPassword(): void {
    this.submitted = true;
    ConfirmedValidator('password', 'c_password')(this.resetForm);

    logger.setLogUser(this.resetForm.controls["password"].value);

    validateForm({
      form: this.resetForm,
      onError: () => {
        this.submitted = true;
      }
    });

    this.__userAuthService.reset(Object.assign({}, this.resetForm.value, this.resetData)).subscribe({
      next: () => {
        if (this.__secureService.isAuthenticated("USER")) {
          // This will send the user into the profile process.
          // If their application is complete this will take them to the status page
          this.__router.navigate(['/']);
          this.resetForm.reset();
          this.resetFormCheck = false;
        } else {
          this.loginIncorrect = true;
          this.loginIncorrect_msg = 'Invalid Username Or Password !';
          this.__router.navigate(['/user/login']);
        }
      },
      error: (err) => {
        if (err.statusCode && err.statusCode === 422) {
          return;
        }

        alert("There was an error resetting your password. Please try again.");
        console.error(JSON.stringify(err));
        throw new Error("There was an error saving resetting the password");
        ;
      }
    })
  }

}
