import { Component, OnInit } from '@angular/core';
import { AdminUserService } from 'src/app/service/admin-user.service';
import { WellFormedError } from 'src/app/model/error.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { validateForm } from '../../../util/form.util';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { logger } from 'src/app/util/logger.util';
import { Roles } from 'src/app/model/role.model';
import { Graduating_Years } from 'src/app/model/user.model';

const className = "UserAddComponent";

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.scss']
})
export class UserAddComponent implements OnInit {

  public registerForm: FormGroup;
  public submitted: boolean = false;

  options = Graduating_Years;
  model: any;
  myDateValue: any;

  bsValue: Date | null = null;
  maxDate = new Date();
  minDate = new Date('1901-01-01');

  get roleName() {
    const roleId = this.f['role'].value;

    if (typeof roleId !== 'number') {
      return '';
    }

    const role = Roles.find(role => role.id === roleId);

    if (!role) {
      return '';
    }

    return role.name;
  }

  constructor(
    private __adminUserService: AdminUserService,
    private __formBuilder: FormBuilder,
    private __router: Router,
  ) {
    this.registerForm = this.__formBuilder.group({
      firstname: ['', [Validators.required]],
      middlename: ['', []],
      lastname: ['', [Validators.required]],
      email: ['', [Validators.required]],
      dob_string: ['', []],
      graduting_class: ['', [Validators.required]],
      role: [0, [Validators.required]],
      linkedin_url: ['', []],
      phone_number: ['', []],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  ngOnInit(): void {
    const signature = className + ".ngOnInit: ";
    this.myDateValue = new Date();
    logger.debug(signature + "MinDate: " + this.minDate);
  }

  onDateChange(newDate: Date) {
    const signature = className + ".onDateChange: ";
    logger.debug(signature + newDate.toString());
  }

  popup: boolean = false;

  saveFunction() {
    const signature = className + ".saveFunction: ";

    validateForm({
      form: this.registerForm,
      onError: () => {
        this.submitted = true;
      }
    });

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    if (!!this.registerForm.controls['dob_string'].value) {
      this.registerForm.controls['dob_string'].setValue(formatDate(this.registerForm.controls['dob_string'].value, 'dd/MM/yyyy', 'en-US'));
    }

    this.popup = false;
    this.__adminUserService.adminUserAdd(this.registerForm.value).subscribe({
      next: () => {
        this.popup = true;
        // this.users = res.data.rows as any;
        alert("User added successfully");
        this.__router.navigate(['/admin']);
      },
      error: (err) => {
        const message = "Error adding user";

        if (err instanceof WellFormedError) {
          alert(err.getMessage(message));
          throw err;
        }

        alert(message);
        logger.error(signature + JSON.stringify(err));
        throw new Error(message);
      }
    });
  }

  hasError(control: string, errorType?: string) {
    if (!this.registerForm.controls[control] || !this.registerForm.controls[control].errors) {
      return false;
    }

    if (!errorType || this.registerForm.controls[control].errors![errorType]) {
      return true;
    }

    return false;
  }

  clickRoleOption(newRole: number) {
    const signature = className + ".clickRoleOption";

    if (newRole === 0) {
      logger.silly(signature + 'Setting student validation');
      this.f['graduting_class'].setValidators([Validators.required]);
    } else {
      logger.silly(signature + 'Setting non-student validation');
      this.f['graduting_class'].setValidators(null);
      this.f['graduting_class'].setValue(null);
    }

    this.f['graduting_class'].updateValueAndValidity();
  }

}
