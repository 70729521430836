<div class="position-relative" draggable *ngIf="item?.skill" [dragData]="item">
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
    y="0px" class="hex-bg" viewBox="0 0 151 131" enable-background="new 0 0 151 131" xml:space="preserve">
    <path d="M113.15,0H37.717L0,65.243l37.717,65.255h75.433l37.719-65.255L113.15,0z" />
  </svg>
  <div class="position-absolute top-50 start-50 translate-middle"><i class="fa-solid fa-plus"></i></div>
  <div class="skill-main" *ngIf="item && item.skill">
    <div class="skill-img">
      <img src="assets/images/site/work-ready.png" alt="skill" *ngIf="item.skill.category === 'work_ready'">
      <img src="assets/images/site/excellence.png" alt="skill" *ngIf="item.skill.category === 'excellence'">
      <img src="assets/images/site/extra-mile.png" alt="skill" *ngIf="item.skill.category === 'the_extra_mile'">
      <img src="assets/images/site/century-light.png" alt="Century Skills and Beyond"
        *ngIf="item.skill.category === 'future_ready_skills'">
    </div>
    <div class="skill-content w-100">
      <h6 class="cat_name">{{getDisplayText() || 'No Category'}}</h6>
    </div>
  </div>
</div>
<div class="position-relative" droppable *ngIf="!item?.skill" (onDrop)="onHexaDrop($event,item?.id)" pTooltip="Click to Add New Skill" tooltipPosition="top">
  <div class="position-absolute top-50 start-50 translate-middle hex-icon"><i class="fa-solid fa-plus"></i></div>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
    y="0px" class="hex-bg" viewBox="0 0 151 131" enable-background="new 0 0 151 131" xml:space="preserve">
    <path d="M113.15,0H37.717L0,65.243l37.717,65.255h75.433l37.719-65.255L113.15,0z" />
  </svg>
  
</div>