<div class="main-content admin-content">
  <div class="container">
    <div class="heading text-theme text-center">
      <h1 class="mb-20 text-uppercase">{{loginUser && loginUser.firstname}} {{loginUser && loginUser.lastname}}</h1>
    </div>
    <div class="home-block mw-1200 mx-auto mb-80">
      <div class="row g-20">
        <div class="col-3">
          <div class="border-box text-center">
            <p>DATE OF BIRTH</p>
            <h3 class="text-theme mb-0">{{(loginUser && loginUser.dob_string) || "Unknown"}}</h3>
          </div>
        </div>
        <div class="col-6">
          <div class="border-box text-center">
            <p>SCHOOL</p>
            <h3 class="text-theme mb-0">{{loginUser && loginUser.school || "GENESIS CHRISTIAN COLLEGE"}}</h3>
          </div>
        </div>
        <div class="col-3">
          <div class="border-box text-center">
            <p>Graduating Year</p>
            <h3 class="text-theme mb-0">{{(loginUser && loginUser.graduting_class)|| "Unknown"}}</h3>
          </div>
        </div>
      </div>
    </div>

    <app-skill-grid [grids]="grids" (gridItemClick)="handleGridItemClick($event)"></app-skill-grid>
  </div>
</div>

<app-skill-form [(isActive)]="showSkillForm" (reloadGrid)="listSkills()" [activeGridItem]="activeGridItem" #skillForm
  (isActiveChange)="handleSkillFormActiveChange($event)"></app-skill-form>

<ng-template #template>
  <div class="modal-header" #content>
    <h5 class="modal-title pull-left">Select a grid item to add your first skill</h5>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Click on any of the grey grid items to start adding skills to your Skills Portfolio.</p>
    <p>Once you have added your skills and would like to print your skills portfolio and attachments, press the print
      button.</p>
    <div class="text-end">
      <button type="button" class="btn btn-danger" (click)="modalRef?.hide()">Close</button>
    </div>
  </div>
</ng-template>
