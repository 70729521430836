import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { createUrl } from '../util/api.util';
import { IUserSkill } from '../model/user-skill.model';
import { StatusMessageData } from '../model/api.model';
import { QueryResult } from '../model/query.filter.class';

@Injectable({
  providedIn: 'root'
})
export class SkillService {

  // When set to true, prevents the tutorial on the skill grid from being displayed a second time
  public hasSeenTutorial: boolean = false;

  constructor(
    private __http: HttpClient,
  ) { }

  /**
   * userSkillAdd
   */
  public userSkillAdd(credentials: {}) {
    return this.__http.post<StatusMessageData<{skill:IUserSkill}>>(createUrl('user_skill', 'add'), credentials);
  }
  /**
   * userSkillEdit
   */
  public userSkillEdit(credentials: {}) {
    return this.__http.post<StatusMessageData<{skill:IUserSkill}>>(createUrl('user_skill', 'edit'), credentials);
  }
  /**
   * userSkillHexaSequenceEdit
   */
  public userSkillHexaSequenceEdit(credentials: {}) {
    return this.__http.post<StatusMessageData<{skill:IUserSkill}>>(createUrl('user_skill', 'edit_hexa_sequence'), credentials);
  }
  /**
   * userSkillListing
   */
  public userSkillListing(credentials: {}) {
    return this.__http.post<StatusMessageData<QueryResult<IUserSkill>>>(createUrl('user_skill', 'list'), credentials);
  }
  /**
   * userSkillEdit
   */
  public userSkillDelete(credentials: {}) {
    return this.__http.post<StatusMessageData<{skill:IUserSkill}>>(createUrl('user_skill', 'delete'), credentials);
  }
}
