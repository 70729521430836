export const Graduating_Years = [2021, 2022, 2023, 2024, 2025, 2026];

export type CurrentUser = {
  id: number;
  email: string;
  is_verify: boolean;
  gender: string;
  firstname: string;
  lastname: string;
  middlename: string;
  impairment: string;
  auth_challenge: string;
  dob_string: string;
  graduting_class: string | null;
  school: string | null;
  role: number;
  linkedin_url: string | null;
  phone_number: string | null;
}