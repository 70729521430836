import { Component, OnInit } from '@angular/core';
import { AdminUserService } from 'src/app/service/admin-user.service';
import { WellFormedError } from 'src/app/model/error.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { validateForm } from '../../../util/form.util';
import { formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { logger } from 'src/app/util/logger.util';
import { combineLatest } from 'rxjs';
import { Roles } from 'src/app/model/role.model';
import { Graduating_Years } from 'src/app/model/user.model';

const className = "UserEditComponent";

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})

export class UserEditComponent implements OnInit {

  public editUserForm: FormGroup;
  public submitted: boolean = false;

  options = Graduating_Years;
  model: any;
  myDateValue: any;

  bsValue: Date | null = null;
  maxDate = new Date();
  minDate = new Date('1901-01-01');

  paramData: any;
  Useremail: string = '';
  role: number | null = null;

  get roleName() {
    const roleId = this.f['role'].value;

    if (typeof roleId !== 'number') {
      return '';
    }

    const role = Roles.find(role => role.id === roleId);

    if (!role) {
      return '';
    }

    return role.name;
  }

  constructor(
    private __adminUserService: AdminUserService,
    private __formBuilder: FormBuilder,
    private __router: Router,
    private __activatedRoute: ActivatedRoute,
  ) {
    this.editUserForm = this.__formBuilder.group({
      firstname: ['', [Validators.required]],
      middlename: ['', []],
      lastname: ['', [Validators.required]],
      // email: ['', [Validators.required]],
      dob_string: ['', []],
      graduting_class: ['', [Validators.required]],
      linkedin_url: ['', []],
      phone_number: ['', []],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.editUserForm.controls; }

  ngOnInit(): void {
    const signature = className + ".ngOnInit: ";
    this.myDateValue = new Date();
    logger.debug(signature + "MinDate: " + this.minDate);

    combineLatest([
      this.__activatedRoute.params,
      this.__activatedRoute.queryParams
    ]).subscribe(data => {
      let temp = Object.assign({}, ...data);
      this.paramData = Object.assign({}, ...data);
      this.paramData['id'] = parseInt(temp['id']);
    });

    this.__adminUserService.getUserDetails(this.paramData['id']).subscribe({
      next: (res: any) => {
        if (res.status) {
          const birthdate = res.data.user.dob_string;
          if (birthdate) {
            const bDate = birthdate.split('/');
            this.bsValue = new Date(`${bDate[2]}/${bDate[1]}/${bDate[0]}`);
          }
          this.editUserForm.patchValue(res.data.user);
          this.role = res.data.user.role;
          this.Useremail = res.data.user.email;
        }
        else {
          this.__router.navigate(['/admin']);
        }
      },
      error: (err) => {
        const message = "Invalid Account Details";

        if (err instanceof WellFormedError) {
          alert(err.getMessage(message));
          throw err;
        }

        alert(message);
        logger.error(signature + JSON.stringify(err));
        throw new Error(message);
      }
    })
  }

  onDateChange(newDate: Date) {
    const signature = className + ".onDateChange: ";
    logger.debug(signature + newDate.toString());
  }

  popup: boolean = false;

  saveFunction() {
    const signature = className + ".saveFunction: ";
    this.popup = false;

    validateForm({
      form: this.editUserForm,
      onError: () => {
        this.submitted = true;
      }
    });
    // stop here if form is invalid
    if (this.editUserForm.invalid) {
      return;
    }
    this.editUserForm.controls['dob_string'].setValue(formatDate(this.editUserForm.controls['dob_string'].value, 'dd/MM/yyyy', 'en-US'));

    logger.debug(signature + JSON.stringify(this.editUserForm.value));
    let data = this.editUserForm.value;
    data.id = this.paramData['id'];
    this.__adminUserService.adminUserEdit(data).subscribe(
      (res: any) => {
        this.popup = true;
        // this.users = res.data.rows as any;
        alert("User updated successfully");
        this.__router.navigate(['/admin']);
      },
      (err) => {
        const message = "Invalid Account Details";

        if (err instanceof WellFormedError) {
          alert(err.getMessage(message));
          throw err;
        }

        alert(message);
        logger.error(signature + JSON.stringify(err));
        throw new Error(message);
      });
  }

  hasError(control: string, errorType?: string) {
    if (!this.editUserForm.controls[control] || !this.editUserForm.controls[control].errors) {
      return false;
    }

    if (!errorType || this.editUserForm.controls[control].errors![errorType]) {
      return true;
    }

    return false;
  }

}
