export const categoryData = [
  {
    "value": "work_ready",
    "text": "Work Ready",
    "children": [
      {
        "value": "life_saving_certficate",
        "text": "Life Saving certficate"
      },
      {
        "value": "white_card",
        "text": "White card"
      },
      {
        "value": "rsa_responsible_serving_of_alcohol",
        "text": "RSA (Responsible Serving of Alcohol)"
      },
      {
        "value": "first_aid_certificate",
        "text": "First Aid certificate"
      },
      {
        "value": "employer_references",
        "text": "Employer References"
      },
      {
        "value": "linkedin_profile",
        "text": "LinkedIn Profile"
      },
      {
        "value": "resume",
        "text": "Resume"
      }, {
        "value": "short_course",
        "text": "Short Course"
      },
      {
        "value": "short_courses_ict",
        "text": "Short Courses - ICT"
      },
      {
        "value": "vet_transcript",
        "text": "VET Transcript"
      },
      {
        "value": "short_cources_communication",
        "text": "Short Courses - Communication"
      },
      {
        "value": "short_courses_literacy",
        "text": "Short Courses - Literacy"
      },
      {
        "value": "short_courses_numeracy",
        "text": "Short Courses - Numeracy"
      },
      {
        "value": "queensland_certificate_of_education",
        "text": "Queensland Certificate of Education (QCE)"
      }
    ]
  },
  {
    "value": "excellence",
    "text": "Excellence",
    "children": [
      {
        "value": "leader_alpha_course",
        "text": "Leader - Alpha Course"
      },
      {
        "value": "leader_prayer_group",
        "text": "Leader - Prayer Group"
      },
      {
        "value": "student_chaplain",
        "text": "Student Chaplain"
      },
      {
        "value": "australian_honours_ensemble_program_ahep",
        "text": "Australian Honours Ensemble Program (AHEP)"
      },
      {
        "value": "state_honours_ensemble_porgram_shep",
        "text": "State Honours Ensemble Porgram (SHEP)"
      },
      {
        "value": "state_representive_the_arts",
        "text": "State Representive - The Arts"
      },
      {
        "value": "state_representive_sport",
        "text": "State Representive - Sport"
      },
      {
        "value": "captain_performing_arts",
        "text": "Captain - Performing Arts"
      },
      {
        "value": "captain_choir",
        "text": "Captain - Choir"
      },
      {
        "value": "leaderof_a_band_ensemble",
        "text": "Leader - Band/Ensemble"
      },
      {
        "value": "captain_college",
        "text": "Captain - College"
      },
      {
        "value": "national_champion",
        "text": "National Champion"
      },
      {
        "value": "captain_college_vice_captain",
        "text": "Captain - College vice captain"
      },
      {
        "value": "captain_school_house",
        "text": "Captain - School House"
      },
      {
        "value": "captain_music",
        "text": "Captain - Music"
      },
      {
        "value": "college_prefect",
        "text": "College Prefect"
      },
      {
        "value": "form_class_representative",
        "text": "Form Class Rep"
      },
      {
        "value": "academic_excellence",
        "text": "Academic Excellence"
      },
      {
        "value": "first_in_subject",
        "text": "First in Subject"
      },
      {
        "value": "first_in_year",
        "text": "First in Year"
      },
      {
        "value": "sporting_representitive",
        "text": "Sporting Rep"
      },
      {
        "value": "captain_sport",
        "text": "Captain - Sport"
      },
      {
        "value": "academic_endeavour_award",
        "text": "Academic Endeavour award"
      },
      {
        "value": "sporting_excellence_award",
        "text": "Sporting Excellence award"
      },
      {
        "value": "special_sporting_award",
        "text": "Special Sporting Award"
      },
      {
        "value": "special_nominated_award",
        "text": "Special Nominated award"
      },
      {
        "value": "high_academic_achievement",
        "text": "High Academic Achievement"
      },
      {
        "value": "academic_achievement",
        "text": "Academic Achievement"
      },
      {
        "value": "vet_student_of_the_year_award",
        "text": "VET student of the Year award"
      },
      {
        "value": "vet_apprentice_trainee_of_the_year_award",
        "text": "VET Apprentice/Trainee of the Year Award"
      },
      {
        "value": "first_colours",
        "text": "First Colours"
      },
      {
        "value": "half_colours",
        "text": "Half Colours"
      },
      {
        "value": "sporting_awards",
        "text": "Sporting Awards"
      },
      {
        "value": "arts_awards",
        "text": "Arts Awards"
      },
      {
        "value": "full_colours",
        "text": "Full Colours"
      },
      {
        "value": "ambassador_award",
        "text": "Ambassador Award"
      },
      {
        "value": "short_course",
        "text": "Short Course"
      },
    ]
  },
  {
    "value": "the_extra_mile",
    "text": "The Extra Mile",
    "children": [
      {
        "value": "cadet",
        "text": "Cadet"
      },
      {
        "value": "volunteer_clean_up_australia_vounteer",
        "text": "Volunteer - Clean Up Australia vounteer"
      },
      {
        "value": "volunteer_make_a_difference",
        "text": "Volunteer - Make a Difference "
      },
      {
        "value": "service_to_the_community_gold",
        "text": "Service to the Community - Gold"
      },
      {
        "value": "service_to_community_silver",
        "text": "Service to Community - Silver"
      },
      {
        "value": "service_to_community_bronze",
        "text": "Service to Community - Bronze"
      },
      {
        "value": "service_to_college_gold",
        "text": "Service to College - Gold"
      },
      {
        "value": "service_to_college_silver",
        "text": "Service to College - Silver"
      },
      {
        "value": "service_to_college_bronze",
        "text": "Service to College - Bronze"
      },
      {
        "value": "volunteer_sports",
        "text": "Volunteer Sports"
      },
      {
        "value": "competitions",
        "text": "Competitions"
      },
      {
        "value": "debating",
        "text": "Debating"
      },
      {
        "value": "ethics_olympiad",
        "text": "Ethics Olympiad"
      },
      {
        "value": "genesis_film_society",
        "text": "Genesis Film Society"
      },
      {
        "value": "mooting_competition",
        "text": "Mooting Competition"
      },
      {
        "value": "readers_cup",
        "text": "Readers Cup"
      },
      {
        "value": "tournament_of_the_minds",
        "text": "Tournament of the Minds"
      },
      {
        "value": "robotics_team",
        "text": "Robotics Team"
      },
      {
        "value": "interact_club",
        "text": "Interact Club"
      },
      {
        "value": "student_library_council",
        "text": "Student Library Council"
      },
      {
        "value": "music_bands_choirs_ensembles",
        "text": "Music/Bands/Choirs/Ensembles"
      },
      {
        "value": "dance_company",
        "text": "Dance Company"
      },
      {
        "value": "shakefest",
        "text": "Shakefest"
      },
      {
        "value": "musical_cast_member",
        "text": "Musical cast member"
      },
      {
        "value": "public_speaking",
        "text": "Public Speaking"
      },
      {
        "value": "short_course",
        "text": "Short Course"
      },
    ]
  },
  {
    "value": "future_ready_skills",
    "text": "Future Ready Skills",
    "children": [
      {
        "value": "member_of_a_club_group",
        "text": "Member of a club/group"
      },
      {
        "value": "communication",
        "text": "Communication"
      },
      {
        "value": "collaboration_working_with_others",
        "text": "Collaboration (working with others)"
      },
      {
        "value": "ethical_thinking_and_actions",
        "text": "Ethical thinking and actions"
      },
      {
        "value": "teamwork",
        "text": "Teamwork"
      },
      {
        "value": "problem_solving",
        "text": "Problem Solving"
      },
      {
        "value": "creative_thinking",
        "text": "Creative Thinking"
      },
      {
        "value": "ict_skills",
        "text": "ICT skills"
      },
      {
        "value": "short_course",
        "text": "Short Course"
      },
    ]
  }
];
