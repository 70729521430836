import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { WellFormedError } from '../model/error.model';
import { SecureService } from '../service/secure.service';
import { UserAuthService } from '../service/user-auth.service';
import { validateForm } from '../util/form.util';
import { logger } from '../util/logger.util';
import { hasKey } from '../util/object.util';
import { ConfirmedValidator } from '../util/validators/confirmed.validator';

const className = "UserLoginComponent";

@Component({
  selector: 'app-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss']
})
export class UserLoginComponent implements OnInit {

  title = 'Login';

  public loginForm: FormGroup;
  public submitted: boolean = false;
  public loginErr: string = "";
  public loginFormCheck: boolean = true;
  public warn: string = "warn";
  public loginBlock: boolean = true;
  public forgotBlock: boolean = false;
  public resetBlock: boolean = false;
  public challengeInput: boolean = false;

  private resetToken: string = "";

  constructor(
    private __formBuilder: FormBuilder,
    private __userAuthService: UserAuthService,
    private __secureService: SecureService,
    private __router: Router,
    private __activatedRoute: ActivatedRoute,
  ) {

    this.loginForm = this.__formBuilder.group({
      loginEmail: ['', [Validators.required]],
      loginPassword: ['', [Validators.required]],
      c_loginPassword: ['', []],
      loginChallenge: ['', []],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  ngOnInit() {
    const signature = className + ".ngOnInit: ";

    logger.silly(signature, 'Determining Default Url');
    this.__userAuthService.getCurrentUser().subscribe((usr) => {
      const user = this.__userAuthService.currentUserSubject.getValue();
      if (user) {
        switch (user.role) {
          case 0:
            logger.silly(signature + 'Navigating User to Default Url[/home/skill]');
            this.__router.navigate(['/home/skill']);
            break;
          case 1:
            logger.silly(signature + 'Navigating Admin to Default Url[/admin]');
            this.__router.navigate(['/admin']);
            break;
          default:
            throw new Error(`Unsupported User Role ${user.role}`);
        }
      }
    });

    combineLatest([
      this.__activatedRoute.params,
      this.__activatedRoute.queryParams,
      this.__activatedRoute.data
    ]).subscribe(([params, queryParams, data]) => {
      if (hasKey(data, 'resetBlock')) {
        this.forgotBlock = false;
        this.loginBlock = false;
        this.resetBlock = true;

        this.f["c_loginPassword"].setValidators([Validators.required]);
        this.f["c_loginPassword"].updateValueAndValidity();
      }

      if (hasKey(params, "token") && typeof params.token === 'string') {
        this.resetToken = params.token;
      }

      if (hasKey(queryParams, 'email')) {
        this.f["loginEmail"].setValue(queryParams.email);
        this.f["loginEmail"].updateValueAndValidity();
      }
    })
  }

  /**
   * login
   */
  public login(): void {
    logger.setLogUser(this.loginForm.controls["loginEmail"].value);

    validateForm({
      form: this.loginForm,
      onError: () => {
        this.submitted = true;
      }
    });

    this.__userAuthService.login(this.loginForm.value).subscribe(
      res => {

        if (hasKey(res, 'loginChallenge')) {
          this.challengeInput = true;
          this.f["loginChallenge"].setValidators([Validators.required]);
          this.f["loginChallenge"].updateValueAndValidity();
          return;
        }

        if (this.__secureService.isAuthenticated("USER")) {
           this.__userAuthService.getCurrentUser().subscribe(user => {
            if(user.data.role == 0)
            {
              this.__router.navigate(['/home']);
            }
            else{
              this.__router.navigate(['/admin']);
            }
          });
          // This will send the user into the profile process.
          // If their application is complete this will take them to the status page
        } else {
          this.cancelForgot();
        }
      },
      (err) => {
        const message = "Invalid Account Credentials";

        if (err instanceof WellFormedError) {
          alert(err.getMessage(message));
          throw err;
        }

        alert(message);
        console.error(JSON.stringify(err));
        throw new Error(message);
      });
  }

  public sendForgotPassword(): void {
    logger.setLogUser(this.loginForm.controls["loginEmail"].value);

    validateForm({
      form: this.loginForm
    });

    this.__userAuthService.forgot({ email: this.f["loginEmail"].value }).subscribe({
      next: () => {

        this.f["loginPassword"].setValue("");
        this.f["loginPassword"].updateValueAndValidity();

        this.cancelForgot();
      },
      error: (err) => {
        if (err.statusCode && err.statusCode === 422) {
          return;
        }

        throw (err);
      }
    })
  }

  public resetPassword(): void {
    ConfirmedValidator('loginPassword', 'c_loginPassword')(this.loginForm);

    logger.setLogUser(this.loginForm.controls["loginEmail"].value);

    validateForm({
      form: this.loginForm,
      onError: () => {
        this.submitted = true;
      }
    });

    this.__userAuthService.reset({
      email: this.f["loginEmail"].value,
      resetToken: this.resetToken,
      password: this.f["c_loginPassword"].value,
      c_password: this.f["loginPassword"].value
    }).subscribe({
      next: () => {
        if (this.__secureService.isAuthenticated("USER")) {
          // This will send the user into the profile process.
          // If their application is complete this will take them to the status page
          this.__router.navigate(['/home']);
        } else {
          this.cancelForgot();
        }
      },
      error: (err) => {
        if (err.statusCode && err.statusCode === 422) {
          return;
        }

        alert("There was an error resetting your password. Please try again.");
        console.error(JSON.stringify(err));
        throw new Error("There was an error saving resetting the password");
        ;
      }
    })
  }

  public cancelForgot() {
    this.forgotBlock = false;
    this.loginBlock = true;
    this.resetBlock = false;
    this.f["loginPassword"].setValidators([Validators.required]);
    this.f["loginPassword"].updateValueAndValidity();

    this.f["c_loginPassword"].setValidators([]);
    this.f["c_loginPassword"].updateValueAndValidity();

    this.f["loginChallenge"].setValidators([]);
    this.f["loginChallenge"].updateValueAndValidity();
  }

  public forgotPassword() {
    this.loginBlock = false;
    this.forgotBlock = true;
    this.f["loginPassword"].setValidators([]);
    this.f["loginPassword"].updateValueAndValidity();

    this.f["c_loginPassword"].setValidators([]);
    this.f["c_loginPassword"].updateValueAndValidity();

    this.f["loginChallenge"].setValidators([]);
    this.f["loginChallenge"].updateValueAndValidity();
    this.__router.navigate(['user/forgot-password']);
  }

  hasError(control: string, errorType?: string) {
    if (!this.loginForm.controls[control] || !this.loginForm.controls[control].errors) {
      return false;
    }

    if (!errorType || this.loginForm.controls[control].errors![errorType]) {
      return true;
    }

    return false;
  }
}