import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IUserGridItem } from './skill-grid-model';
import { UserAuthService } from 'src/app/service/user-auth.service';
import { hasKey } from 'src/app/util/object.util';
import { logger } from 'src/app/util/logger.util';
import { SkillService } from 'src/app/service/skill.service';
import { WellFormedError } from 'src/app/model/error.model';

const className = "SkillGridComponent";

@Component({
  selector: 'app-skill-grid',
  templateUrl: './skill-grid.component.html',
  styleUrls: ['./skill-grid.component.scss']
})
export class SkillGridComponent implements OnInit {

  public isEdit = false;
  public mainCategory: any = "";

  title = 'Add Skill';

  @Input()
  public grids: IUserGridItem[] = [];

  @Output()
  public gridItemClick: EventEmitter<IUserGridItem> = new EventEmitter();

  constructor(
    private __skillService: SkillService,
  ) { }

  ngOnInit(): void {}

  public handleGridItemClick(gridItem: IUserGridItem): void {
    this.gridItemClick.emit(gridItem);
  }

  updateSkillGrid(data: { from: IUserGridItem, to: number }): void {
    const signature = className + ".updateSkillGrid: ";
    if (!data.from.skill) {
      logger.error(signature + `Attempted to move skill at Grid[${data.from.id}] to Grid[${data.to}] but no skill was found`);
      throw new Error("No skill found to move");
    }
    logger.silly(signature + `Moving Skill[${data.from.skill.id}] to Grid[${data.to}]`);

    // If the skill isn't stored serverside
    if( !data.from.skill.id ) {
      this.finalizeSkillMove(data);
      return;
    }

    this.__skillService.userSkillHexaSequenceEdit({
      hexa_sequence_number: data.to,
      id: data.from.skill.id
    }).subscribe({
      next: res => {
        this.finalizeSkillMove(data);
      },
      error: (err) => {
        const message = "Unknown Error";

        if (err instanceof WellFormedError) {
          alert(err.getMessage(message));
          throw err;
        }

        alert(message);
        console.error(JSON.stringify(err));
        throw new Error(message);
      }
    });
  }

  finalizeSkillMove(data: { from: IUserGridItem, to: number }): void {
    const destinationGrid = this.grids.find(grid => grid.id === data.to);
    if (!destinationGrid) {
      throw new Error("Unable to locate destination grid");
    }

    destinationGrid.skill = data.from.skill;
    delete data.from.skill;

    if( data.from.originalSkill ) {
      destinationGrid.originalSkill = data.from.originalSkill;
      delete data.from.originalSkill;
    }
  }
}
