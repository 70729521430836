<app-skill-legend></app-skill-legend>

<div class="border-box homeHexBg-block minH-580 mb-30 mt-4">
  <app-skill-category-hex *ngFor="let grid of grids" class="position-relative" [ngClass]="{ 
      'themeBg-skyblue': grid.skill && !grid.skill.isDraft && grid.skill.category === 'work_ready',
      'themeBg-gray': grid.skill && ( grid.skill.isDraft || !['work_ready','excellence','the_extra_mile','future_ready_skills'].includes(grid.skill.category) ),
      'themeBg-blue': grid.skill && !grid.skill.isDraft && grid.skill.category === 'excellence',
      'themeBg-orange': grid.skill && !grid.skill.isDraft && grid.skill.category === 'the_extra_mile',
      'themeBg-brown': grid.skill && !grid.skill.isDraft && grid.skill.category === 'future_ready_skills'
    }" (click)="handleGridItemClick(grid)" [item]="grid" (newDragEvent)="updateSkillGrid($event)">
  </app-skill-category-hex>
</div>