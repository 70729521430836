import { Component, OnInit } from '@angular/core';
import { AdminUserService } from 'src/app/service/admin-user.service';
import { WellFormedError } from 'src/app/model/error.model';
import { environment } from 'src/environments/environment';
import { logger } from 'src/app/util/logger.util';

const className = "UserListComponent";

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  constructor(private __adminUserService: AdminUserService) { }
  p: number = 1;
  public totalCount: number = 0;
  public currentPage: number = 1;
  public pageLimit: number = environment.defaultCollectionLimit;
  public sortField: string | null = 'lastname';
  public sortDirection: 'ASC' | 'DESC' | null = 'ASC';

  users = <any>[];
  ngOnInit(): void {
    this.listUsers("");
  }

  searchText = '';

  public listUsers(searchText: string | null = null) {
    const signature = className + ".listUsers: ";
    const search = searchText ? searchText : this.searchText;
    this.__adminUserService.adminUserListing({ page: this.currentPage, limit: this.pageLimit, search, sortBy: this.sortField, sortDirection: this.sortDirection }).subscribe(
      (res: any) => {
        this.totalCount = res.data.count;
        this.users = res.data.rows as any;
        logger.debug(signature + 'Response:', res.data.rows);
      },
      (err: any) => {
        const message = "Invalid Account Credentials";

        if (err instanceof WellFormedError) {
          alert(err.getMessage(message));
          throw err;
        }

        alert(message);
        logger.error(signature + JSON.stringify(err));
        throw new Error(message);
      });
  }

  pageChanged(page: number) {
    this.currentPage = page;
    this.listUsers();
  }

  sortingChanged(event: { field: string | null, direction: 'ASC' | 'DESC' }) {
    this.sortField = event.field;
    this.sortDirection = event.direction;
    this.listUsers();
  }

  public enableDisableUser(evt: any, user: any) {
    let status = evt.target.checked;
    const signature = className + ".enableDisableUser: ";
    this.__adminUserService.adminUserUpdate({ id: user.id, is_verify: status }).subscribe(
      (res) => {
        this.listUsers();
      },
      (err: any) => {
        const message = "Invalid User Details";

        if (err instanceof WellFormedError) {
          alert(err.getMessage(message));
          throw err;
        }

        alert(message);
        logger.error(signature + JSON.stringify(err));
        throw new Error(message);
      });
  }


  checkEvent(event: KeyboardEvent) {
    if (event.code === 'NumpadEnter' || event.code === 'Enter') {
      this.listUsers();
    }
  }

}
