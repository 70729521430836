<div class="main-content admin-content">
  <div class="container">
    <div class="heading text-theme text-center">
      <h1 class="mb-20">Admin</h1>
    </div>
    <div class="search-block mw-1200 mx-auto mb-60">
      <form action="">
        <div class="row g-20">
          <div class="col-auto"><a href="#" routerLink="/admin/add-user" class="btn btn-theme btn-iconText"><i
                class="fa-solid fa-circle-plus"></i>ADD
              USER</a></div>
          <div class="col">
            <div class="form-floating floating-custom">
              <input type="text" class="form-control form-custom" [(ngModel)]="searchText"
                [ngModelOptions]="{ standalone: true }" id="searchInput" placeholder="text"
                (keyup)="checkEvent($event)">
              <label class="label-custom" for="searchInput">Search</label>
            </div>
          </div>
          <div class="col-auto" (click)="listUsers()">
            <a href="javascript:void(0)" class="btn btn-themelight1 btn-iconText btn-search">
              <i class="fa-solid fa-magnifying-glass"></i>SEARCH
            </a>
          </div>
        </div>
      </form>
    </div>
    <div class="table-responsive">
      <table class="table table-custom table-mob-custom table-admin">
        <thead>
          <tr>
            <th rowspan="2">
              <i sort-icon sortFieldName='lastName' [currentSortField]='sortField'
                [currentSortDirection]='sortDirection' (updateSorting)="sortingChanged($event)"></i>
              Name
            </th>
            <th rowspan="2">
              <i sort-icon sortFieldName='email' [currentSortField]='sortField' [currentSortDirection]='sortDirection'
                (updateSorting)="sortingChanged($event)"></i>
              Email
            </th>
            <th rowspan="2">
              <i sort-icon sortFieldName='graduting_class' [currentSortField]='sortField'
                [currentSortDirection]='sortDirection' (updateSorting)="sortingChanged($event)"></i>
              Graduating year
            </th>
            <th rowspan="2">
              <i sort-icon sortFieldName='last_login' [currentSortField]='sortField'
                [currentSortDirection]='sortDirection' (updateSorting)="sortingChanged($event)"></i>
              Last Login
            </th>
            <th rowspan="2">
              <i sort-icon sortFieldName='is_verify' [currentSortField]='sortField'
                [currentSortDirection]='sortDirection' (updateSorting)="sortingChanged($event)"></i>
              Status
            </th>
            <th rowspan="2">
              <i sort-icon sortFieldName='skillCount' [currentSortField]='sortField'
                [currentSortDirection]='sortDirection' (updateSorting)="sortingChanged($event)"></i>
              Number of Skills
            </th>
            <th rowspan="2">Enable</th>
            <th rowspan="2">Password</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="users.length <= 0">
            <td colspan="9" class="text-center">No records found</td>
          </tr>
          <tr *ngFor="let user of users | paginate: {
                itemsPerPage: pageLimit,
                currentPage: currentPage,
                totalItems :totalCount
            }">

            <td table-head="Name:">
              <i class="fa-solid fa-pen" routerLink="/admin/edit-user/{{user.id}}"></i>
              {{user.firstname+" "+user.lastname }}
              <span *ngIf="user.role === 1" class="text-secondary small">(Admin)</span>
            </td>
            <td table-head="Email:">{{user.email}}</td>
            <td table-head="Graduating year:">{{user.graduting_class}}</td>
            <td table-head="Last Login:">
              {{user.last_login | date : 'h:mm a dd/MM/yyyy'}}
              {{!(user.last_login)?'Never':''}}
            </td>
            <td table-head="Status:">{{(user.is_verify == 1)?'Active':'Closed'}}</td>
            <td table-head="Number of Skill:">{{user.skillCount}}</td>
            <td table-head="Enable:">
              <div class="form-check form-switch d-inline-block ps-0">
                <input class="form-check-input ms-0" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                  [checked]="user.is_verify" (click)="enableDisableUser($event,user)">
              </div>
            </td>
            <td table-head="Password:" *ngIf="user.password == 'NOT_SET' || user.is_verify ==0"><i
                class="fa-solid fa-key"></i></td>
            <td table-head="Password:" *ngIf="user.password != 'NOT_SET' && user.is_verify ==1"><i
                class="fa-solid fa-key text-info"></i></td>
          </tr>
        </tbody>
      </table>
      <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
    </div>
  </div>
</div>
