import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SecureService } from '../service/secure.service';
import { UserAuthService } from '../service/user-auth.service';
import { logger } from '../util/logger.util';
import { hasKey } from '../util/object.util';

const className = "UserVerifyComponent";

@Component({
  selector: 'app-verify',
  templateUrl: './user-verify.component.html',
  styleUrls: ['./user-verify.component.scss']
})
export class UserVerifyComponent implements OnInit {

  title = 'Login';

  public sendingVerificationBlock = true;
  public errorVerifyingBlock = false;
  public successVerifyingBlock = false;

  constructor(
    private __userAuthService: UserAuthService,
    private __activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.validateUserEmail();
  }

  validateUserEmail() {
    const signature = className + ".validateUserEmail";
    this.__activatedRoute.queryParams.subscribe(queryParams => {
      let hasAllValues = true;

      if (!hasKey(queryParams, 'email')) {
        logger.info(signature, `Will fail verification due to missing email in params`);
        hasAllValues = false;
      }

      if (!hasKey(queryParams, 'token')) {
        logger.info(signature, `Will fail verification due to missing token in params`);
        hasAllValues = false;
      }

      const { email, token } = queryParams;

      if (!hasAllValues) {
        this.sendingVerificationBlock = false;
        this.errorVerifyingBlock = true;
        return;
      }

      this.__userAuthService.verify({ email, token }).subscribe({
        next: () => {
          this.sendingVerificationBlock = false;
          this.successVerifyingBlock = true;
        },
        error: err => {
          this.sendingVerificationBlock = false;
          this.errorVerifyingBlock = true;

          alert("There was an error verifying your email. Please try again.");
          console.error(JSON.stringify(err));
          throw new Error("There was an error verifying email");
        }
      });
    })
  }
}